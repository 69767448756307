import React from 'react';
import { ChevronDown } from 'lucide-react';
import type { Subscription } from '../../lib/dal/subscriptions';

interface SubscriptionSelectorProps {
  subscriptions: Subscription[];
  selectedSubscription: Subscription | null;
  onSubscriptionSelect: (subscription: Subscription) => void;
  className?: string; // Add this line
}

export default function SubscriptionSelector({ 
  subscriptions, 
  selectedSubscription, 
  onSubscriptionSelect,
  className = '' // Add this line
}: SubscriptionSelectorProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between w-full px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50"
      >
        <span className="text-sm font-medium text-gray-900">
          {selectedSubscription?.code || 'Select a subscription'}
        </span>
        <ChevronDown className="w-5 h-5 text-gray-400" />
      </button>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto">
          {subscriptions.map(subscription => (
            <button
              key={subscription.id}
              onClick={() => {
                onSubscriptionSelect(subscription);
                setIsOpen(false);
              }}
              className={`w-full px-4 py-2 text-left hover:bg-gray-100 ${
                selectedSubscription?.id === subscription.id ? 'bg-indigo-50' : ''
              }`}
            >
              <div className="font-medium text-gray-900">{subscription.code}</div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
