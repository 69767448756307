import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Route, Users, CalendarDays, Sparkles, BatteryCharging, Target, Brain, Share2, BarChart3, Award, BookOpen, ArrowRight } from 'lucide-react';
import { Button } from './ui/Button';

export default function TestHome() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white">
      {/* Hero Section */}
      <section className="py-20 text-center">
        <motion.div 
          className="max-w-5xl mx-auto px-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
        >
          <h1 className="text-5xl md:text-6xl font-bold mb-6 text-indigo-900">
            Remove Doubt, Take Ownership
          </h1>
          <p className="text-xl md:text-2xl mb-8 text-indigo-700">
            With a Revolutionary Tool for Finding the Insights, Inspiration, and Trends Most People Miss.
          </p>
          <p className="text-lg mb-8 text-indigo-600 max-w-3xl mx-auto">
            Finding Blueberries helps leaders, coaches, and anyone unlock hidden strengths, reduce burnout, and build unstoppable teams—without adding more 'busywork' to your day.
          </p>
          <div className="max-w-4xl mx-auto mb-12 bg-gray-100 rounded-lg overflow-hidden">
            <div className="col-span-1">
              <div className="relative aspect-video rounded-lg overflow-hidden shadow-lg">
                  <iframe
                      src="https://www.youtube.com/embed/g8WVNX8oc10"
                      className="absolute top-0 left-0 w-full h-full"
                      title="YouTube video"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                  />
              </div>
          </div>
          </div>
          <Button
            onClick={() => navigate('/purchase')}
            className="bg-indigo-600 hover:bg-indigo-700 text-white px-8 py-4 text-lg rounded-lg"
          >
            Start Your Journey
          </Button>
        </motion.div>
      </section>

      {/* Feeling Swamped Section */}
      <section className="py-20 bg-white">
        <div className="max-w-6xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-12 text-indigo-900 text-center">
              Building Engagement While Putting Out Fires Can Feel Impossible.
            </h2>
            <div className="space-y-8">
              <div className="space-y-6">
                <div className="flex items-start gap-4">
                  <BatteryCharging className="w-6 h-6 text-indigo-600 mt-1" />
                  <p className="text-indigo-700">
                    Leaders in tech, education, healthcare—and other demanding industries —face a sea of "bears": constant crises, tight deadlines, never-ending change.
                  </p>
                </div>
                <div className="flex items-start gap-4">
                  <Target className="w-6 h-6 text-indigo-600 mt-1" />
                  <p className="text-indigo-700">
                    You're so busy putting out fires that you rarely capture the actual wins your people (and you) achieve daily.
                  </p>
                </div>
                <div className="flex items-start gap-4">
                  <Brain className="w-6 h-6 text-indigo-600 mt-1" />
                  <p className="text-indigo-700">
                    Meanwhile, your best talent risks burnout, as do you —and you may not see it coming until it's too late.
                  </p>
                </div>
              </div>
              <div className="bg-indigo-50 p-8 rounded-lg space-y-6">
                <h3 className="text-xl font-semibold text-indigo-900">What we hear...</h3>
                <ul className="space-y-4 text-indigo-700">
                  <li className="flex items-start gap-2">
                    <span className="text-indigo-400">›</span>
                    "We spend thousands on offsite retreats, but the buzz dies in weeks."
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="text-indigo-400">›</span>
                    "Our performance reviews feel like stale paperwork, and nobody wants to do them."
                  </li>
                  <li className="flex items-start gap-2">
                    <span className="text-indigo-400">›</span>
                    "I worry about turnover, wasted time, and missing out on real progress."
                  </li>
                </ul>
              </div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* How it Works */}
      <section className="py-20">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-indigo-900 text-center">
            How it works
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: <Route className="w-8 h-8 text-indigo-600" />,
                title: "Start With Your Initial Reflection",
                description: "Begin by discovering your natural 'connection' style—how you engage, share value, and make an impact."
              },
              {
                icon: <Share2 className="w-8 h-8 text-indigo-600" />,
                title: "Reflect & Record",
                description: "Each day, respond to a quick prompt using voice-to-text input—no tedious typing required."
              },
              {
                icon: <BarChart3 className="w-8 h-8 text-indigo-600" />,
                title: "Visual Dashboards",
                description: "Track intangible metrics and watch as your 'soft skills' become tangibly measurable."
              }
            ].map((item, index) => (
              <motion.div
                key={index}
                className="bg-white p-8 rounded-lg shadow-lg"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="mb-4">{item.icon}</div>
                <h3 className="text-xl font-semibold mb-4 text-indigo-900">{item.title}</h3>
                <p className="text-indigo-700">{item.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Pricing */}
      <section className="py-20 bg-white">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-indigo-900 text-center">
            Fair, Simple Pricing to Fit Any Pack Size
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                title: "Individual",
                price: "$10",
                period: "/month",
                description: "Perfect for a single leader, coach, or solopreneur.",
                yearlyPrice: "$100/year"
              },
              {
                title: "Teams (5+ People)",
                price: "$8",
                period: "/month/person",
                description: "Bring your entire pack on board.",
                yearlyPrice: "$80/year/person"
              },
              {
                title: "Enterprise (50+)",
                price: "Custom",
                period: "",
                description: "Volume pricing + included certification opportunities.",
                yearlyPrice: "Contact us"
              }
            ].map((plan, index) => (
              <motion.div
                key={index}
                className="bg-indigo-50 p-8 rounded-lg"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <h3 className="text-2xl font-bold mb-4 text-indigo-900">{plan.title}</h3>
                <div className="flex items-end mb-4">
                  <span className="text-4xl font-bold text-indigo-600">{plan.price}</span>
                  <span className="text-indigo-600 ml-1">{plan.period}</span>
                </div>
                <p className="text-indigo-700 mb-4">{plan.description}</p>
                <p className="text-indigo-600 font-medium">{plan.yearlyPrice}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-20">
        <div className="max-w-4xl mx-auto px-4 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6 text-indigo-900">
            Start Finding Blueberries Today
          </h2>
          <p className="text-xl mb-8 text-indigo-700">
            Join Finding Blueberries and finally see the forest (and the blueberries) through the trees.
          </p>
          <Button
            onClick={() => navigate('/buy')}
            className="bg-indigo-600 hover:bg-indigo-700 text-white px-8 py-4 text-lg rounded-lg"
          >
            Get Started for $10/Month
          </Button>
        </div>
      </section>

      <footer className="bg-indigo-50 py-12">
        <div className="max-w-6xl mx-auto px-4 text-center">
          <p className="text-indigo-600">
            Finding Blueberries is dedicated to making everyday achievements visible and building stronger connections—helping leaders and coaches bring out the best in every team.
          </p>
          <div className="mt-6 flex justify-center gap-4">
            <button className="text-indigo-600 hover:text-indigo-700">Twitter</button>
            <button className="text-indigo-600 hover:text-indigo-700">LinkedIn</button>
            <button className="text-indigo-600 hover:text-indigo-700">Contact Us</button>
          </div>
          <p className="mt-8 text-indigo-400">
            &copy; {new Date().getFullYear()} Finding Blueberries. All rights reserved.
          </p>
        </div>
      </footer>

      {/* Foundations Button */}
      <motion.button
        onClick={() => navigate("/curation/foundations")}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="group inline-flex items-center space-x-4 px-8 py-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-xl text-xl font-semibold shadow-lg hover:from-indigo-700 hover:to-purple-700 transition-all duration-200 w-full justify-center"
      >
        <BookOpen className="w-6 h-6 transition-transform group-hover:rotate-6" />
        <span>Foundations</span>
        <ArrowRight className="w-6 h-6 transition-transform group-hover:translate-x-1" />
      </motion.button>
    </div>
  );
}
