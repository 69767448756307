import { 
    collection, 
    query,
    where,
    Firestore, 
    CollectionReference, 
    DocumentData, 
    updateDoc,
    doc, 
    getDoc, 
    orderBy,
    getDocs,
    setDoc,
    deleteDoc
} from 'firebase/firestore';
import { AccessLevel } from '../../enums/AccessLevel';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebase';

export interface FbbUserData {
    firstName?: string;
    lastName?: string;
    email?: string;
    access_level?: AccessLevel;
    group?: string;
    membership_start?: string;
    membership_end?: string;
    stripe_customer_id?: string;
    subscription_ids?: string[];
    group_admin_for?: string[];
    lastSignInTime?: string;
    phone?: string;
  }

export interface FbbUser extends FbbUserData {
    id: string;
    createdAt: string;
}

export interface UserMap {
    [key: string]: FbbUser;
}

// create interface for user functions
export interface IUserRepository {
    getUser: (userId: string) => Promise<FbbUser>;
    getUsers: (userIds: []) => Promise<FbbUser[]>;
    getAllUsers: () => Promise<UserMap>;
    createUser: (user: FbbUserData, initialPassword: string) => Promise<FbbUser>;
    createUserWithoutLogin: (user: FbbUserData, initialPassword: string, subscriptionId: string) => Promise<FbbUser>;
    updateUser: (user: FbbUser) => Promise<FbbUser>;
    deleteUser: (userId: string) => Promise<void>;
}

export class MockUserRepository implements IUserRepository {

    async getUser(userId: string): Promise<FbbUser> {
        console.log('getUser', userId);
        return Promise.resolve({} as FbbUser);
    }

    async getUsers(userIds: []): Promise<FbbUser[]> {
        console.log('getUsers');
        return Promise.resolve([]);
    }

    async createUser(user: FbbUserData, initialPassword: string): Promise<FbbUser> {
       console.log(`createUser: ${JSON.stringify(user)} ${initialPassword}`);
       return Promise.resolve(user as FbbUser);
    }

    async createUserWithoutLogin(user: FbbUserData, initialPassword: string, subscriptionId: string): Promise<FbbUser> {
        console.log('createUserWithoutLogin', user);
        return Promise.resolve(user as FbbUser);
    }

    async updateUser(user: FbbUser): Promise<FbbUser> {
        console.log('updateUser', user);
        return Promise.resolve(user);
    }

    async deleteUser(userId: string): Promise<void> {
        console.log('deleteUser', userId);
        return Promise.resolve();
    }

    async getAllUsers(): Promise<UserMap> {
        console.log('getAllUsers');
        return Promise.resolve({});
    }
}

const USERTABLE = 'users';
//TODO move to a firebase specific file 
export class FirebaseUserRepository implements IUserRepository {
    private db: Firestore;
    private usersCollectionRef: CollectionReference<DocumentData>;

    constructor() {
        this.db = db;
        this.usersCollectionRef = collection(this.db, USERTABLE);
    }

    async getUser(userId: string): Promise<FbbUser> {
        const userDoc = await getDoc(doc(this.db, USERTABLE, userId)); 
        if (!userDoc.exists) {
            throw new Error('User not found');
        }
        const user = userDoc.data() as FbbUser;
        return { ...user, id: userDoc.id };
    }

    async getUsers(userIds: []): Promise<FbbUser[]> {
        try {
            if (userIds.length === 0) return [];
            
            const q = query(
                this.usersCollectionRef,
                where('__name__', 'in', userIds)
            );
            
            const snapshot = await getDocs(q);
            console.log(`Found ${snapshot.size} users from ${userIds.length} IDs`);
            
            return snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            } as FbbUser));
        } catch (error) {
            console.error('Error fetching users:', error);
            throw error;
        }
    }

    async createUser(user: FbbUserData, initialPassword: string): Promise<FbbUser> {

        if (!user.email || !initialPassword) {
            throw new Error('Missing required fields to create Finding Blueberries User.');
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, user.email, initialPassword);
            const userId = await userCredential.user.uid;
            const creationTimestamp = new Date().toISOString();

            let userData = { ...user,
                createdAt: creationTimestamp,
            }

            // Create user document
            await setDoc(doc(db, 'users', userId), userData);
            return { ...userData, id: userId } as FbbUser;
        } catch (error) {
            throw new Error('Failed to create user: ' + error);
        }
    }

    async createUserWithoutLogin(user: FbbUserData, initialPassword: string, subscriptionId: string): Promise<FbbUser> {
        try {
            // create firebase user
            const createResponse = await fetch(`/api/user-admin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: user.email,
                password: initialPassword,
                displayName: `${user.firstName} ${user.lastName}`,
                })
            });

            if (!createResponse.ok) {
                throw new Error('Failed to create user');
            }
            const data = await createResponse.json();
            const userId = data.user.uid;
            const creationTimestamp = new Date().toISOString();

            let userData = { ...user,
                createdAt: creationTimestamp,
                subscription_ids: [subscriptionId]
            }

            // Create user document
            await setDoc(doc(db, 'users', userId), userData);
            return { ...userData, id: userId } as FbbUser;

        } catch (error) {    
            console.error('Error creating user:', error);
            throw new Error('Failed to create user');
        }
    }

    async updateUser(user: FbbUser): Promise<FbbUser> {
        const userRef = doc(this.db, USERTABLE, user.id);
        await updateDoc(userRef, {...user}, { merge: true });
        return user;
    }

    async deleteUser(userId: string): Promise<void> {
        throw new Error('Method not implemented.');
        await deleteDoc(doc(this.db, USERTABLE, userId));
    }

    async getAllUsers(): Promise<UserMap> {
        throw new Error('Method not implemented.');
        const q = query(this.usersCollectionRef, orderBy('lastName'));
        const querySnapshot = await getDocs(q);

        return querySnapshot.docs.reduce((acc, doc) => ({
            ...acc,
            [doc.id]: {
              id: doc.id,
              ...doc.data()
            } as FbbUser
          }), {} as UserMap);
    }
}

export class UserService {
    private userRepository: IUserRepository;

    constructor(userRepository: IUserRepository) {
        this.userRepository = userRepository;
    }

    async getUser(userId: string): Promise<FbbUser> {
        return this.userRepository.getUser(userId);
    }

    async getUsers(userIds): Promise<FbbUser[]> {
        return this.userRepository.getUsers(userIds);
    }

    async createUser(user: FbbUserData, initialPassword: string): Promise<FbbUser> {
        return this.userRepository.createUser(user, initialPassword);
    }

    async createUserWithoutLogin(user: FbbUserData, initialPassword: string, subscriptionId: string): Promise<FbbUser> {
        return this.userRepository.createUserWithoutLogin(user, initialPassword, subscriptionId);
    }

    async updateUser(user: FbbUser | any): Promise<FbbUser> {
        return this.userRepository.updateUser(user);
    }

    async deleteUser(userId: string): Promise<void> {
        return this.userRepository.deleteUser(userId);
    }

    async getAllUsers(): Promise<UserMap> {
        return this.userRepository.getAllUsers();
    }
}
