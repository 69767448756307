import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Confetti from 'react-confetti';
import { doc, updateDoc, collection, addDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { useAuth } from '../../contexts/AuthContext';

interface Question {
  text: string;
  options: Array<{
    text: string;
    type: 'Storyteller' | 'Guide' | 'Confidant' | 'Sage'
  }>;
}

const questions: Question[] = [
  {
    text: "In a team discussion, do you prefer to:",
    options: [
      { text: "Share relevant personal experiences to illustrate your ideas", type: "Storyteller" },
      { text: "Offer thoughtful suggestions to help solve problems", type: "Guide" },
      { text: "Ask questions that encourage deeper thinking", type: "Sage" },
      { text: "Listen and provide supportive feedback", type: "Confidant" }
    ]
  },
  {
    text: "When helping others, you typically:",
    options: [
      { text: "Share similar experiences you've had", type: "Storyteller" },
      { text: "Suggest specific actions they could take", type: "Guide" },
      { text: "Help them gain new perspectives", type: "Sage" },
      { text: "Create a safe space for them to open up", type: "Confidant" }
    ]
  },
  {
    text: "Your natural communication style is:",
    options: [
      { text: "Engaging and anecdotal", type: "Storyteller" },
      { text: "Clear and action-oriented", type: "Guide" },
      { text: "Thought-provoking and analytical", type: "Sage" },
      { text: "Empathetic and supportive", type: "Confidant" }
    ]
  },
  {
    text: "In difficult situations, you tend to:",
    options: [
      { text: "Share how you overcame similar challenges", type: "Storyteller" },
      { text: "Outline practical steps forward", type: "Guide" },
      { text: "Explore underlying causes and patterns", type: "Sage" },
      { text: "Offer emotional support and understanding", type: "Confidant" }
    ]
  },
  {
    text: "Your preferred way of building trust is:",
    options: [
      { text: "Sharing personal experiences and being authentic", type: "Storyteller" },
      { text: "Being reliable and following through on commitments", type: "Guide" },
      { text: "Demonstrating wisdom and insight", type: "Sage" },
      { text: "Showing genuine care and maintaining confidentiality", type: "Confidant" }
    ]
  },
  {
    text: "When someone faces a challenge, you first:",
    options: [
      { text: "Tell them about a similar situation you faced", type: "Storyteller" },
      { text: "Help them create an action plan", type: "Guide" },
      { text: "Ask questions to understand the root cause", type: "Sage" },
      { text: "Listen and validate their feelings", type: "Confidant" }
    ]
  },
  {
    text: "Your strongest contribution to a team is:",
    options: [
      { text: "Making ideas relatable through stories", type: "Storyteller" },
      { text: "Helping turn ideas into action", type: "Guide" },
      { text: "Bringing depth to discussions", type: "Sage" },
      { text: "Creating psychological safety", type: "Confidant" }
    ]
  },
  {
    text: "You feel most energized when:",
    options: [
      { text: "Sharing experiences that resonate with others", type: "Storyteller" },
      { text: "Seeing others implement your advice", type: "Guide" },
      { text: "Having deep, meaningful discussions", type: "Sage" },
      { text: "Being there for someone in need", type: "Confidant" }
    ]
  },
  {
    text: "Your approach to giving feedback is:",
    options: [
      { text: "Sharing relevant examples from your experience", type: "Storyteller" },
      { text: "Providing specific, actionable suggestions", type: "Guide" },
      { text: "Asking reflective questions", type: "Sage" },
      { text: "Being gentle and supportive", type: "Confidant" }
    ]
  },
  {
    text: "In group settings, you naturally:",
    options: [
      { text: "Share stories to build connections", type: "Storyteller" },
      { text: "Help the group stay focused and productive", type: "Guide" },
      { text: "Raise thought-provoking questions", type: "Sage" },
      { text: "Ensure everyone feels included", type: "Confidant" }
    ]
  },
  {
    text: "Your preferred way of motivating others is:",
    options: [
      { text: "Sharing inspiring personal stories", type: "Storyteller" },
      { text: "Setting clear goals and action steps", type: "Guide" },
      { text: "Helping them discover their own insights", type: "Sage" },
      { text: "Offering encouragement and emotional support", type: "Confidant" }
    ]
  },
  {
    text: "When solving problems, you tend to:",
    options: [
      { text: "Draw from past experiences", type: "Storyteller" },
      { text: "Focus on practical solutions", type: "Guide" },
      { text: "Analyze patterns and implications", type: "Sage" },
      { text: "Consider emotional impacts", type: "Confidant" }
    ]
  },
  {
    text: "Your natural leadership style is:",
    options: [
      { text: "Leading by example through shared experiences", type: "Storyteller" },
      { text: "Setting clear direction and expectations", type: "Guide" },
      { text: "Fostering intellectual growth and insight", type: "Sage" },
      { text: "Building strong emotional connections", type: "Confidant" }
    ]
  },
  {
    text: "When celebrating success, you prefer to:",
    options: [
      { text: "Share the story of the journey", type: "Storyteller" },
      { text: "Acknowledge specific achievements", type: "Guide" },
      { text: "Reflect on lessons learned", type: "Sage" },
      { text: "Focus on the team's emotional experience", type: "Confidant" }
    ]
  },
  {
    text: "Your ideal way of influencing others is:",
    options: [
      { text: "Through relatable stories and experiences", type: "Storyteller" },
      { text: "By demonstrating clear paths to success", type: "Guide" },
      { text: "By stimulating new ways of thinking", type: "Sage" },
      { text: "Through genuine emotional connection", type: "Confidant" }
    ]
  }
];

export default function ConnectorType() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<{primary: string, secondary: string} | null>(null);
  const { user } = useAuth();

  const shuffleOptions = (options: typeof questions[0]['options']) => {
    return [...options].sort(() => Math.random() - 0.5);
  };

  const [shuffledQuestions] = useState(() => 
    questions.map(q => ({
      ...q,
      options: shuffleOptions(q.options)
    }))
  );

  const calculateResults = () => {
    const scores = {
      Storyteller: 0,
      Guide: 0,
      Confidant: 0,
      Sage: 0
    };

    answers.forEach((type) => {
      scores[type as keyof typeof scores]++;
    });

    const sortedTypes = Object.entries(scores)
      .sort(([,a], [,b]) => b - a);

    return {
      primary: sortedTypes[0][0],
      secondary: sortedTypes[1][0]
    };
  };

  const handleAnswer = (type: string) => {
    const newAnswers = [...answers];
    newAnswers[currentQuestion] = type;
    setAnswers(newAnswers);
  };

  const handleComplete = async (finalAnswers: string[]) => {
    if (!user || finalAnswers.length !== questions.length) return;

    setIsLoading(true);
    const timestamp = new Date().toISOString();

    try {
      // Calculate results first
      const result = calculateResults();
      console.log("Calculated results:", result);

      // Immediately set results to state
      setResults(result);

      // Then save assessment data
      const assessmentData = {
        userId: user.uid,
        questions: questions.map((q, index) => ({
          text: q.text,
          answer: finalAnswers[index],
          options: q.options
        })),
        answers: finalAnswers,
        completedAt: timestamp,
        updatedAt: timestamp,
        results: {
          primary: result.primary,
          secondary: result.secondary
        }
      };

      // Save to Firebase
      await Promise.all([
        addDoc(collection(db, 'completedConnectorTypes'), assessmentData),
        updateDoc(doc(db, 'users', user.uid), {
          connector_type: {
            primary: result.primary,
            secondary: result.secondary,
            completedAt: timestamp
          }
        })
      ]);

      // Show success animation
      // <Confetti
      //   width={window.innerWidth}
      //   height={window.innerHeight}
      //   recycle={false}
      //   numberOfPieces={500}
      // />

      // toast.success('Connector Type assessment completed!');
    } catch (error) {
      console.error('Error saving results:', error);
      // toast.error('Failed to save results');
    } finally {
      setIsLoading(false);
    }
  };

  const simulateAssessment = async () => {
    const simulatedAnswers = questions.map(() => {
      const randomIndex = Math.floor(Math.random() * 4);
      return questions[0].options[randomIndex].type; // Assuming all questions have 4 options
    });
    await handleComplete(simulatedAnswers);
  };

  const progress = ((currentQuestion + 1) / questions.length) * 100;

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-indigo-50 to-white">
        <div className="text-center">
          <div className="w-16 h-16 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
          <p className="text-indigo-600 font-medium">Calculating your Connector Type...</p>
        </div>
      </div>
    );
  }

  if (results) {
    return (
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="min-h-screen flex items-center justify-center bg-gradient-to-b from-indigo-50 to-white p-4"
      >
        <div className="max-w-lg w-full bg-white rounded-xl shadow-lg p-8 text-center">
          <h1 className="text-3xl font-bold text-indigo-900 mb-6">
            Your Connector Type
          </h1>
          <div className="space-y-6">
            <div className="p-6 bg-indigo-50 rounded-lg">
              <h2 className="text-xl font-semibold text-indigo-900 mb-2">
                Primary: {results.primary}
              </h2>
              <p className="text-indigo-700">
                This is your natural way of connecting with others.
              </p>
            </div>
            <div className="p-6 bg-purple-50 rounded-lg">
              <h2 className="text-xl font-semibold text-purple-900 mb-2">
                Secondary: {results.secondary}
              </h2>
              <p className="text-purple-700">
                This complements your primary style.
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-indigo-50 to-white p-4">
      <div className="max-w-2xl mx-auto pt-12">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-indigo-900">
            Connector Type Assessment
          </h1>
          <button
            onClick={simulateAssessment}
            className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700"
          >
            Test Assessment
          </button>
        </div>

        <div className="mb-8">
          <div className="h-2 bg-gray-200 rounded-full">
            <div 
              className="h-2 bg-indigo-500 rounded-full transition-all duration-300"
              style={{ width: `${progress}%` }}
            />
          </div>
          <p className="text-sm text-gray-600 mt-2">
            Question {currentQuestion + 1} of {questions.length}
          </p>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-8">
          <h2 className="text-xl font-semibold text-gray-900 mb-6">
            {shuffledQuestions[currentQuestion].text}
          </h2>

          <div className="space-y-4">
            {shuffledQuestions[currentQuestion].options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleAnswer(option.type)}
                className={`w-full text-left p-4 rounded-lg border transition-all duration-200
                  ${answers[currentQuestion] === option.type
                    ? 'bg-indigo-600 text-white border-indigo-700 shadow-md' 
                    : 'border-gray-200 hover:border-indigo-500 hover:bg-indigo-50'
                  }`}
              >
                {option.text}
              </button>
            ))}
          </div>

          {answers[currentQuestion] && (
            <div className="mt-6 flex justify-end">
              <button
                onClick={async () => {
                  if (currentQuestion < questions.length - 1) {
                    setCurrentQuestion(prev => prev + 1);
                  } else {
                    await handleComplete(answers);
                  }
                }}
                disabled={isLoading || !answers[currentQuestion]}
                className={`px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors flex items-center space-x-2 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {isLoading && <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />}
                <span>
                  {isLoading ? 'Processing...' : currentQuestion < questions.length - 1 ? 'Next Question' : 'Complete Assessment'}
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}