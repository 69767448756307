import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, MessageCircle, ChevronDown, ChevronUp, Download, Share2 } from 'lucide-react';
import { collection, addDoc } from 'firebase/firestore';
import { doc, getDoc, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import ReactMarkdown from 'react-markdown';
import { db } from '../../lib/firebase';
import { toast } from 'react-hot-toast';
import { PDFGenerator } from './PDFGenerator';
import type { CompletedTheme } from '../../lib/firebase/types';
import { createAndCopyShareLink } from '../../lib/utils/share';

interface ThemeSummaryViewerProps {
  isOpen: boolean;
  onClose: () => void;
  completedThemeId: string; 
  userId: string; 
}

export default function ThemeSummaryViewer({
  isOpen,
  onClose,
  completedThemeId,
  userId
}: ThemeSummaryViewerProps) {
  const [loading, setLoading] = React.useState(true);
  const [showResponses, setShowResponses] = React.useState(false);
  const [completedTheme, setCompletedTheme] = React.useState<CompletedTheme | null>(null);
  const [showPDFModal, setShowPDFModal] = React.useState(false);

  const handleShare = async () => {
    if (!completedTheme) return;

    try {
      await createAndCopyShareLink({
        summary: completedTheme.summary,
        userId
      });
    } catch (error) {
      console.error('Error in handleShare:', error);
    }
  };

  // Memoize onClose if it's recreated by parent
  const memoizedOnClose = React.useCallback(onClose, []);

  React.useEffect(() => {
    let mounted = true;

    const loadTheme = async () => {
      if (!isOpen || !completedThemeId || !userId) return;

      const maxRetries = 3;
      const baseDelay = 1000; // 1 second

      const attemptLoad = async (retry: number): Promise<void> => {
        if (!mounted) return;

        try {
          setLoading(true);
          const docRef = doc(db, 'completedThemes', completedThemeId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists() && mounted) {
            setCompletedTheme({ id: docSnap.id, ...docSnap.data() } as CompletedTheme);
          } else if (mounted) {
            if (retry < maxRetries) {
              console.log(`Attempt ${retry + 1}: Document not found, retrying...`);
              const delay = baseDelay * Math.pow(2, retry);
              await new Promise(resolve => setTimeout(resolve, delay));
              return attemptLoad(retry + 1);
            }
            toast.error('Unable to load theme summary');
            memoizedOnClose();
          }
        } catch (error) {
          if (mounted) {
            console.error('Error loading theme summary:', error);
            toast.error('Failed to load theme summary');
            memoizedOnClose();
          }
        } finally {
          if (mounted) {
            setLoading(false);
          }
        }
      };

      await attemptLoad(0);
    };

    loadTheme();

    return () => {
      mounted = false;
    };
  }, [isOpen, completedThemeId, userId, memoizedOnClose]);

  if (!isOpen || !completedTheme) return null;

  return (
    <AnimatePresence mode="wait">
      {isOpen && completedThemeId && (
        <>
          <motion.div
            key={`overlay-${completedThemeId}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black"
            onClick={onClose}
          />
          <motion.div
            key={`modal-${completedThemeId}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="fixed inset-0 flex items-center justify-center p-4 z-50"
          >
            <div className="bg-white rounded-xl max-w-4xl w-full p-8 relative max-h-[90vh] overflow-y-auto">
              {loading ? (
                <div className="flex justify-center">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600" />
                </div>
              ) : (
                <>
                  <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold text-gray-900">
                      {completedTheme.theme_name || 'Theme Summary'}
                    </h2>
                    <div className="flex items-center space-x-4">
                      <button
                        onClick={handleShare}
                        className="p-2 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg"
                      >
                        <Share2 className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => setShowPDFModal(true)}
                        className="p-2 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg"
                      >
                        <Download className="w-5 h-5" />
                      </button>
                      <button
                        onClick={onClose}
                        className="p-2 text-gray-400 hover:text-gray-600"
                      >
                        <X className="w-5 h-5" />
                      </button>
                    </div>
                  </div>

                  <button
                    onClick={() => setShowResponses(!showResponses)}
                    className="w-full flex items-center justify-between p-4 bg-purple-50 rounded-lg hover:bg-purple-100 mb-6"
                  >
                    <div className="flex items-center space-x-2">
                      <MessageCircle className="w-5 h-5 text-purple-600" />
                      <span className="font-medium text-purple-900">Your Responses</span>
                    </div>
                    {showResponses ? (
                      <ChevronUp className="w-5 h-5 text-purple-600" />
                    ) : (
                      <ChevronDown className="w-5 h-5 text-purple-600" />
                    )}
                  </button>

                  {showResponses && (
                    <div className="mb-6 space-y-4 bg-white p-6 rounded-lg border border-purple-100">
                      {completedTheme.additionalContext && (
                        <div className="mb-4 pb-4 border-b border-purple-100">
                          <p className="font-medium text-purple-900">Additional Context</p>
                          <p className="text-gray-600 whitespace-pre-wrap">{completedTheme.additionalContext}</p>
                        </div>
                      )}
                      {completedTheme.responses.map((response) => (
                        <div key={response.questionId} className="space-y-2 border-b border-purple-100 pb-4 mb-4 last:border-b-0">
                          <div className="flex justify-between items-start mb-2">
                            <p className="font-medium text-purple-900">{response.question}</p>
                            <div className="text-sm text-purple-600">
                              {response.userName && <p>From: {response.userName}</p>}
                              {response.themeName && <p>Theme: {response.themeName}</p>}
                            </div>
                          </div>
                          <p className="text-gray-600 whitespace-pre-wrap">{response.content}</p>
                        </div>
                      ))}
                    </div>
                  )}

                  <div className="prose max-w-none [&_ul]:list-none [&_ul]:pl-0 [&_ul>li]:relative [&_ul>li]:pl-6 [&_ul>li]:before:content-['•'] [&_ul>li]:before:absolute [&_ul>li]:before:left-0 [&_ul>li]:before:text-gray-900">
                    <ReactMarkdown>{completedTheme.summary}</ReactMarkdown>
                  </div>
                </>
              )}
            </div>
          </motion.div>
        </>
      )}

      {completedTheme && (
        <PDFGenerator
          isOpen={showPDFModal}
          onClose={() => setShowPDFModal(false)}
          title={completedTheme.theme_name || 'Theme Summary'}
          content={completedTheme.summary}
          createdFor={completedTheme.createdFor}
          responses={completedTheme.responses.map(r => ({
            question: r.question,
            content: r.content
          }))}
        />
      )}
    </AnimatePresence>
  );
}