
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import { X } from 'lucide-react';

interface SummaryModalProps {
  isVisible: boolean;
  onClose: () => void;
  summaryData: {
    questions: Array<{
      question: string;
      answer: string;
    }>;
    summary: string;
    theme_name?: string;
  };
}

export const SummaryModal: React.FC<SummaryModalProps> = ({
  isVisible,
  onClose,
  summaryData
}) => {
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className="bg-white rounded-xl p-6 max-w-2xl w-full max-h-[80vh] overflow-y-auto relative"
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
        >
          <X className="w-5 h-5" />
        </button>
        <h2 className="text-2xl font-bold text-indigo-900 mb-4">{summaryData.theme_name || 'Reflection Summary'}</h2>
        <div className="prose max-w-none">
          <ReactMarkdown
            components={{
              h1: ({ children }) => (
                <h1 className="text-3xl font-bold text-indigo-900 mb-6">{children}</h1>
              ),
              h2: ({ children }) => (
                <h2 className="text-2xl font-semibold text-indigo-800 mt-8 mb-4">{children}</h2>
              ),
              p: ({ children }) => (
                <p className="text-indigo-700 mb-4">{children}</p>
              ),
              ul: ({ children }) => (
                <ul className="list-disc list-inside space-y-2 text-indigo-700 mb-4">{children}</ul>
              ),
              li: ({ children }) => (
                <li className="text-indigo-700">{children}</li>
              ),
            }}
          >
            {summaryData.summary}
          </ReactMarkdown>
        </div>
        <button
          onClick={onClose}
          className="mt-6 px-6 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
        >
          Close
        </button>
      </motion.div>
    </div>
  );
};
