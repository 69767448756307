import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
} from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import { generateStructuredInfo } from "../../lib/openai";
import { FileText } from "lucide-react";
import { motion } from "framer-motion";
import { toast } from "react-hot-toast";
import { db } from "../../lib/firebase";
import { auth } from "../../lib/firebase";
import { Users, UserPlus, UserMinus } from "lucide-react";
import AddGroupMemberModal from "./AddGroupMemberModal";
import GroupSelector from "./GroupSelector";
import ThemeSummaryViewer from "../shared/ThemeSummaryViewer";
import type { CompletedTheme } from "../../lib/firebase/types";

export interface Group {
  id: string;
  group_name: string;
  group_code: string;
  total_seats: number;
  seats_used: number;
  seats_remaining: number;
  members: string[];
}

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

// TODO: add subscription management
// - show subscription details
//  -- show end date and auto renew status
// - add/remove seats
// - cancel subscription - stop future payments
// - the groups entity should have a subscription_id field
// - what other information do we need to store for subscriptions?
// - how do we handle adding seats mid-subscription?  prorate the cost without modifying the subscription end date?
// - or extend the subscription end date?

/*

Users can request these changes at any time during their billing cycle, but due to the 
prepaid nature of subscriptions, such modifications can only take effect at the end of the cycle.
This is handled by prorating (adding more seats) and changinge the auto renewal (canceling the subscription).

The state of the subscription should be maintained in the firestore database.  We should
not rely on the stripe API to determine the state of the subscription.

*/

export default function GroupAdminDashboard() {
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState<Group[]>([]);
  const [members, setMembers] = useState<User[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);

  useEffect(() => {
    loadGroups();
  }, []);

  const loadGroups = async () => {
    try {
      setLoading(true);

      const groupsToLoad = new Set();

      // Get current user's document to check group_admin_for array
      const userDoc = await getDoc(
        doc(db, "users", auth.currentUser?.uid || ""),
      );
      const userData = userDoc.data();

      // Load groups from group_admin_for array
      if (userData?.group_admin_for?.length > 0) {
        const groupsRef = collection(db, "groups");
        const q = query(
          groupsRef,
          where("__name__", "in", userData.group_admin_for),
        );
        const snapshot = await getDocs(q);
        snapshot.docs.forEach((doc) =>
          groupsToLoad.add({
            id: doc.id,
            ...doc.data(),
          }),
        );
      }

      const uniqueGroups = Array.from(groupsToLoad);

      if (uniqueGroups.length === 0) {
        console.log("User has no admin groups");
        setGroups([]);
        setLoading(false);
        return;
      }

      console.log("Loaded admin groups:", uniqueGroups);
      setGroups(uniqueGroups);

      if (uniqueGroups.length > 0) {
        setSelectedGroup(uniqueGroups[0]);
        await loadMembers(uniqueGroups[0]);
      }
    } catch (error) {
      console.error("Error loading groups:", error);
      toast.error("Failed to load groups");
    } finally {
      setLoading(false);
    }
  };

  const loadMembers = async (group: Group) => {
    try {
      const members = await Promise.all(
        group.members.map(async (userId) => {
          const userDoc = await getDoc(doc(db, "users", userId));
          return {
            id: userDoc.id,
            ...userDoc.data(),
          } as User;
        }),
      );
      setMembers(members);
    } catch (error) {
      console.error("Error loading members:", error);
      toast.error("Failed to load group members");
    }
  };

  const handleRemoveMember = async (userId: string) => {
    if (
      !selectedGroup ||
      !window.confirm("Are you sure you want to remove this member?")
    ) {
      return;
    }

    try {
      const groupRef = doc(db, "groups", selectedGroup.id);
      await updateDoc(groupRef, {
        members: selectedGroup.members.filter((id) => id !== userId),
        seats_used: selectedGroup.seats_used - 1,
        seats_remaining: selectedGroup.seats_remaining + 1,
      });

      // Update user's group access
      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, {
        group: null,
        access_level: "free",
      });

      toast.success("Member removed successfully");
      await loadGroups();
    } catch (error) {
      console.error("Error removing member:", error);
      toast.error("Failed to remove member");
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-sm rounded-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h2 className="text-xl font-semibold text-gray-900">My Groups</h2>
          <div className="mt-4">
            <GroupSelector
              groups={groups}
              selectedGroup={selectedGroup}
              onGroupSelect={async (group) => {
                setSelectedGroup(group);
                await loadMembers(group);
              }}
            />
          </div>
        </div>
        <button
          onClick={() => setShowAddMemberModal(true)}
          disabled={!selectedGroup?.seats_remaining}
          className="flex items-center space-x-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
        >
          <UserPlus className="w-4 h-4" />
          <span>Add Member</span>
        </button>
      </div>

      {selectedGroup && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            <div className="bg-indigo-50 p-4 rounded-lg">
              <div className="flex items-center space-x-2 text-indigo-600 mb-2">
                <Users className="w-5 h-5" />
                <span className="font-medium">Total Seats</span>
              </div>
              <p className="text-2xl font-bold text-indigo-900">
                {selectedGroup.total_seats}
              </p>
            </div>

            <div className="bg-green-50 p-4 rounded-lg">
              <div className="flex items-center space-x-2 text-green-600 mb-2">
                <UserPlus className="w-5 h-5" />
                <span className="font-medium">Available Seats</span>
              </div>
              <p className="text-2xl font-bold text-green-900">
                {selectedGroup.seats_remaining}
              </p>
            </div>

            <div className="bg-purple-50 p-4 rounded-lg">
              <div className="flex items-center space-x-2 text-purple-600 mb-2">
                <span className="font-medium">Group Code</span>
              </div>
              <p className="text-2xl font-bold text-purple-900">
                {selectedGroup.group_code}
              </p>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Member Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Email
                  </th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {members.map((member) => (
                  <tr key={member.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {member.firstName} {member.lastName}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {member.email}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right">
                      <button
                        onClick={() => handleRemoveMember(member.id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        {auth.currentUser?.uid !== member.id && (
                          <UserMinus className="w-5 h-5" />
                        )}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}

      {showAddMemberModal && selectedGroup && (
        <AddGroupMemberModal
          isOpen={showAddMemberModal}
          onClose={() => setShowAddMemberModal(false)}
          group={selectedGroup}
          onMemberAdded={loadGroups}
        />
      )}

      {selectedGroup && (
        <div className="mt-8">
          <h3 className="text-xl font-semibold text-gray-900 mb-6">
            Group Summaries
          </h3>
          <CompletedThemesList
            group={selectedGroup}
            userId={auth.currentUser?.uid || ""}
          />
        </div>
      )}
    </div>
  );
}

interface CompletedThemesListProps {
  group: Group;
  userId: string;
}

function CompletedThemesList({ group, userId }: CompletedThemesListProps) {
  const [themes, setThemes] = useState<CompletedTheme[]>([]);
  const [selectedThemes, setSelectedThemes] = useState<string[]>([]);
  const [showTitleModal, setShowTitleModal] = useState(false);
  const [metaSummaryTitle, setMetaSummaryTitle] = useState("");
  const [additionalContext, setAdditionalContext] = useState("");
  const [isGeneratingMetaSummary, setIsGeneratingMetaSummary] = useState(false);
  const [showMetaSummaryModal, setShowMetaSummaryModal] = useState(false);
  const [metaSummaryResponse, setMetaSummaryResponse] = useState<any>(null);
  const [selectedThemeId, setSelectedThemeId] = useState<string | null>(null);
  const { userData } = useAuth();

  if (!userData) {
    return null;
  }

  useEffect(() => {
    loadCompletedThemes();
  }, [group]);

  const loadCompletedThemes = async () => {
    try {
      // Get themes completed by group members
      const memberThemesQuery = query(
        collection(db, "completedThemes"),
        where("userId", "in", group.members),
      );

      // Get themes created by admin for this group
      const adminThemesQuery = query(
        collection(db, "completedThemes"),
        where("group_id", "==", group.id),
      );

      const [memberThemesSnapshot, adminThemesSnapshot] = await Promise.all([
        getDocs(memberThemesQuery),
        getDocs(adminThemesQuery),
      ]);

      const allThemes = [
        ...memberThemesSnapshot.docs,
        ...adminThemesSnapshot.docs,
      ]
        .map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            }) as CompletedTheme,
        )
        .sort((a, b) => {
          const dateA = new Date(a.completedAt || 0).getTime();
          const dateB = new Date(b.completedAt || 0).getTime();
          return dateB - dateA;
        });

      setThemes(allThemes);
    } catch (error) {
      console.error("Error loading completed themes:", error);
      toast.error("Failed to load completed themes");
    }
  };

  const handleGenerateMetaSummary = async () => {
    setIsGeneratingMetaSummary(true);
    try {
      const summaries = await Promise.all(
        selectedThemes.map(async (themeId) => {
          const theme = themes.find((t) => t.id === themeId);
          return `### From ${theme?.userName || 'Anonymous'} - ${theme?.theme_name || 'Theme'}\n\n${theme?.summary || ""}`;
        }),
      );

      const combinedSummary = summaries.join("\n\n---\n\n");

      const response = await generateStructuredInfo(
        combinedSummary,
        { model: "gpt-4", temperature: 0.7 },
        `You are are team leader pro. Take the following from multiple team members and generate a meta-summary of these completed answers, questions and summaries that highlights key patterns and insights and wins and next steps. Return in markdown without a title but use ### for each section. Mention specific people and activities etc when they are in input.  Incorporate the following additional context if provided (answer their questions including all input data and outside data if helpful): ${additionalContext}`,
      );

      if (!response?.data) {
        throw new Error("Failed to generate meta summary");
      }

      const selectedThemeData = await Promise.all(
        selectedThemes.map(async (themeId) => {
          const theme = themes.find((t) => t.id === themeId);
          return {
            responses: theme?.responses || [],
            summary: theme?.summary || "",
          };
        }),
      );

      const combinedResponses = selectedThemeData.flatMap(
        (theme) => theme.responses,
      );

      const docRef = await addDoc(collection(db, "completedThemes"), {
        userId: userId,
        userName: userData
          ? `${userData.firstName} ${userData.lastName}`
          : "Anonymous",
        theme_name: metaSummaryTitle || "Group Summary",
        channel: "findingblueberries-group",
        summary: response.data,
        completedAt: new Date().toISOString(),
        summary_type: "group_summary",
        group_id: group.id,
        responses: combinedResponses,
        themeId: "meta-summary",
        additionalContext: additionalContext || "",
      });

      setMetaSummaryResponse({ id: docRef.id, summary: response.data });
      setShowMetaSummaryModal(true);
      toast.success("Group summary generated and saved!");
      setSelectedThemes([]);
      await loadCompletedThemes();
    } catch (error) {
      console.error("Error generating meta summary:", error);
      toast.error("Failed to generate meta summary");
    } finally {
      setIsGeneratingMetaSummary(false);
    }
  };

  return (
    <div className="space-y-6">
      {selectedThemes.length > 0 && (
        <button
          onClick={() => setShowTitleModal(true)}
          className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
        >
          Generate Group Summary
        </button>
      )}

      <div className="grid gap-4">
        {themes.map((theme) => (
          <motion.div
            key={theme.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className={`bg-white rounded-xl shadow-sm overflow-hidden cursor-pointer transition-all
              ${selectedThemes.includes(theme.id) ? "ring-2 ring-blue-500 ring-offset-2" : ""}`}
            onClick={() => {
              if (selectedThemes.includes(theme.id)) {
                setSelectedThemes(
                  selectedThemes.filter((id) => id !== theme.id),
                );
              } else {
                setSelectedThemes([...selectedThemes, theme.id]);
              }
            }}
          >
            <div className="px-6 py-4 bg-gradient-to-r from-indigo-50 to-blue-50">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-xl font-semibold text-indigo-900">
                    {theme.theme_name || theme.themeTitle || "Theme Summary"}
                  </h3>
                  <div className="text-sm text-gray-600 mt-2">
                    <p>Created by: {theme.userName}</p>
                    <p>
                      {theme.completedAt &&
                        `Completed on ${new Date(theme.completedAt).toLocaleDateString()}`}
                    </p>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedThemeId(theme.id);
                    }}
                    className="p-2 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg transition-colors"
                  >
                    <FileText className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      {showTitleModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full">
            <h3 className="text-lg font-medium mb-4">Group Summary Title</h3>
            <div className="space-y-4">
              <input
                type="text"
                value={metaSummaryTitle}
                onChange={(e) => setMetaSummaryTitle(e.target.value)}
                placeholder="Enter a title (optional)"
                className="w-full p-2 border border-gray-300 rounded-lg"
              />
              <textarea
                value={additionalContext}
                onChange={(e) => setAdditionalContext(e.target.value)}
                placeholder="Add additional context or questions to guide the summary"
                className="w-full p-2 border border-gray-300 rounded-lg h-24 resize-none"
              />
            </div>
            <div className="flex justify-end space-x-4 mt-4">
              <button
                onClick={() => setShowTitleModal(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setShowTitleModal(false);
                  handleGenerateMetaSummary();
                }}
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 flex items-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={isGeneratingMetaSummary}
              >
                {isGeneratingMetaSummary ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent mr-2" />
                    <span>Generating...</span>
                  </>
                ) : (
                  <span>Generate Summary</span>
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {showMetaSummaryModal && metaSummaryResponse && (
        <div className="fixed inset-0 z-50">
          <ThemeSummaryViewer
            isOpen={showMetaSummaryModal}
            onClose={() => {
              setShowMetaSummaryModal(false);
              setMetaSummaryResponse(null);
            }}
            completedThemeId={metaSummaryResponse.id}
            userId={userId}
          />
        </div>
      )}

      {selectedThemeId && (
        <div className="fixed inset-0 z-50">
          <ThemeSummaryViewer
            isOpen={!!selectedThemeId}
            onClose={() => setSelectedThemeId(null)}
            completedThemeId={selectedThemeId}
            userId={userId}
          />
        </div>
      )}
    </div>
  );
}