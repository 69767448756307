import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { User, LogOut, Settings, ChevronDown, LayoutDashboard, Users, CalendarDays, Route, BookOpen, ShoppingCart } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { AccessLevel } from '../../enums/AccessLevel';

export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, userData, signOut } = useAuth();
  const [showUserMenu, setShowUserMenu] = React.useState(false);
  const menuRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowUserMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const isActive = (path: string) => location.pathname === path;

  const handleNavigation = (path: string) => {
    navigate(path);
    if (location.pathname.includes('/curation/') && path.includes('/curation/')) {
      window.location.reload();
    }
  };

  const menuItems = [
    ...(userData?.access_level !== AccessLevel.Free ? [
      {
        label: 'Daily',
        path: '/curation/daily',
        icon: CalendarDays
      },
      {
        label: 'Milestones',
        path: '/curation/milestones',
        icon: Route
      },
      {
        label: 'Foundations',
        path: '/curation/foundations',
        icon: BookOpen
      }
    ] : []),
    ...(userData?.group_admin ? [{
      label: 'Group Management',
      path: '/admin/group-admin',
      icon: Users
    }] : []),
    ...(userData?.access_level === 'admin' ? [{
      label: 'Admin Dashboard',
      path: '/admin',
      icon: Settings
    }] : [])
  ];

  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between py-4">
          <div className="flex-shrink-0">
            <Link to="/" className="flex items-center space-x-3 hover:opacity-75 transition-opacity">
              <span className="text-2xl">🫐</span>
              <span className="text-lg md:text-xl font-semibold text-indigo-900">Finding Blueberries</span>
            </Link>
          </div>
          <div className="hidden md:flex items-center justify-center flex-1 ml-8">
            {userData?.access_level !== AccessLevel.Free && (
              <nav className="flex items-center space-x-8">
                <button
                  onClick={() => handleNavigation('/curation/daily')}
                  className={`text-gray-700 hover:text-indigo-600 ${isActive('/curation/daily') ? 'text-indigo-600' : ''}`}
                >
                  Daily
                </button>
                <button
                  onClick={() => handleNavigation('/curation/milestones')}
                  className={`text-gray-700 hover:text-indigo-600 ${isActive('/curation/milestones') ? 'text-indigo-600' : ''}`}
                >
                  Milestones
                </button>
                <button
                  onClick={() => handleNavigation('/curation/foundations')}
                  className={`text-gray-700 hover:text-indigo-600 ${isActive('/curation/foundations') ? 'text-indigo-600' : ''}`}
                >
                  Foundations
                </button>
                <Link
                  to="/dashboard"
                  className={`text-gray-700 hover:text-indigo-600 ${isActive('/dashboard') ? 'text-indigo-600' : ''}`}
                >
                  Dashboard
                </Link>
              </nav>
            )}
          </div>
          <div className="flex-shrink-0 ml-4">
            {user ? (
              <div className="relative" ref={menuRef}>
                <button
                  onClick={() => setShowUserMenu(!showUserMenu)}
                  className="flex items-center space-x-2 px-3 py-2 rounded-lg hover:bg-gray-50"
                  aria-expanded={showUserMenu}
                >
                  <div className="w-8 h-8 bg-indigo-100 rounded-full flex items-center justify-center">
                    <User className="w-4 h-4 text-indigo-600" />
                  </div>
                  <div className="flex flex-col items-start">
                    <span className="text-gray-700">{userData?.firstName}</span>
                    {userData?.access_level === AccessLevel.Free && (
                      <span className="text-xs text-red-500">Limited</span>
                    )}
                  </div>
                  <ChevronDown className="w-4 h-4 text-gray-400" />
                </button>
                {showUserMenu && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-50">
                    {userData?.access_level === AccessLevel.Free && (
                      <Link
                        to="/buy"
                        className="flex items-center space-x-2 px-4 py-2 text-gray-700 hover:bg-gray-50"
                        onClick={() => setShowUserMenu(false)}
                      >
                        <ShoppingCart className="w-4 h-4" />
                        <span>Upgrade</span>
                      </Link>
                    )}
                    <Link
                      to="/profile"
                      className="flex items-center space-x-2 px-4 py-2 text-gray-700 hover:bg-gray-50"
                      onClick={() => setShowUserMenu(false)}
                    >
                      <User className="w-4 h-4" />
                      <span>Profile</span>
                    </Link>
                    <Link
                      to="/dashboard"
                      className="flex items-center space-x-2 px-4 py-2 text-gray-700 hover:bg-gray-50"
                      onClick={() => setShowUserMenu(false)}
                    >
                      <LayoutDashboard className="w-4 h-4" />
                      <span>Dashboard</span>
                    </Link>
                    {menuItems.map((item) => (
                      <button
                        key={item.path}
                        onClick={() => handleNavigation(item.path)}
                        className="flex items-center space-x-2 px-4 py-2 text-gray-700 hover:bg-gray-50 w-full"
                      >
                        <item.icon className="w-4 h-4" />
                        <span>{item.label}</span>
                      </button>
                    ))}
                    <button
                      onClick={() => {
                        signOut();
                        setShowUserMenu(false);
                      }}
                      className="w-full flex items-center space-x-2 px-4 py-2 text-gray-700 hover:bg-gray-50"
                    >
                      <LogOut className="w-4 h-4" />
                      <span>Sign Out</span>
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <Link
                to="/login"
                className="flex items-center space-x-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                <User className="w-4 h-4" />
                <span>Sign In</span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}