import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';

interface ThemeOverviewProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  introduction: string;
  showCreatedFor?: boolean;
  onStart: () => void;
  onCreatedForChange?: (value: string) => void; 
  createdFor?: string; 
}

export default function ThemeOverview({ 
  isOpen, 
  onClose, 
  title, 
  description,
  introduction,
  showCreatedFor,
  onStart,
  onCreatedForChange,
  createdFor
}: ThemeOverviewProps) {
  const [localCreatedFor, setLocalCreatedFor] = React.useState(createdFor || '');

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter' || e.key === 'Escape') {
        onClose();
        if (e.key === 'Enter' && isOpen) {
          onStart();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [onClose, onStart]);

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-[100]">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black"
            onClick={onClose}
          />
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="fixed inset-0 flex items-center justify-center p-4 z-[100]"
          >
            <div className="bg-white rounded-xl shadow-xl max-w-xl w-full p-8 relative min-h-[60vh] flex flex-col">
              <button
                onClick={onClose}
                className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
              >
                <X className="w-5 h-5" />
              </button>

              <div className="space-y-6">
                <div>
                  <h2 className="text-3xl font-bold text-purple-900 mb-3">{title}</h2>
                  <p className="text-lg text-purple-700">{description}</p>
                </div>
                
                <div className="flex-1">
                {showCreatedFor && (
                  <div>
                    <label className="block text-lg font-medium text-gray-700 mb-2">
                      Who are you creating this for?
                    </label>
                    <input
                      type="text"
                      value={localCreatedFor}
                      onChange={(e) => {
                        setLocalCreatedFor(e.target.value);
                        onCreatedForChange?.(e.target.value);
                      }}
                      placeholder="Enter name"
                      className="w-full px-4 py-3 text-lg border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                    />
                  </div>
                )}
                {introduction && (
                  <div className="mt-6">
                    <p className="text-lg text-gray-700">{introduction}</p>
                  </div>
                )}
                </div>

                <div className="pt-6 flex justify-end">
                  <button
                    onClick={() => {
                      onClose();
                      onStart();
                    }}
                    className="px-8 py-4 bg-purple-600 text-white text-lg font-medium rounded-lg hover:bg-purple-700 transition-colors"
                  >
                    Get Started
                  </button>
                </div>

                <p className="text-sm text-gray-500 text-center">
                  Press Enter to start or Escape to go back
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}