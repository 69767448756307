
import React from 'react';
import { ArrowRight } from 'lucide-react';

interface LoadingButtonProps {
  isLoading: boolean;
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  showArrow?: boolean;
}

export const LoadingButton: React.FC<LoadingButtonProps> = ({
  isLoading,
  onClick,
  children,
  disabled = false,
  showArrow = false
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled || isLoading}
      className="flex items-center space-x-2 px-8 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 disabled:opacity-50 transition-colors text-lg font-medium"
    >
      {isLoading ? (
        <>
          <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2" />
          <span>Loading...</span>
        </>
      ) : (
        <>
          <span>{children}</span>
          {showArrow && <ArrowRight className="w-4 h-4" />}
        </>
      )}
    </button>
  );
};
