
import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { toast } from 'react-hot-toast';

export function RequirePaidAccess({ children }: { children: React.ReactNode }) {
  const { userData } = useAuth();
  const [hasShownMessage, setHasShownMessage] = useState(false);
  const location = useLocation();

  const freePaths = ['/dashboard', '/profile'];
  const currentPath = location.pathname;

  if (userData?.access_level === 'free' && !freePaths.includes(currentPath)) {
    if (!hasShownMessage) {
      toast.error('This feature requires a paid membership. Please upgrade to access.');
      setHasShownMessage(true);
    }
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
}
