import React, { useState, useEffect } from 'react';
import type { Curation } from '../../lib/firebase/types';

interface EditCurationModalProps {
  curation: Curation;
  onClose: () => void;
  onSave: (curation: Curation) => void;
  existingRoutes: string[];
}

export default function EditCurationModal({ curation, onClose, onSave, existingRoutes = [] }: EditCurationModalProps) {
  const [title, setTitle] = useState(curation.title);
  const [videoUrl, setVideoUrl] = useState(curation.videoUrl);
  const [explainer, setExplainer] = useState(curation.explainer);
  const [route, setRoute] = useState(curation.route);
  const [isRouteValid, setIsRouteValid] = useState(true);
  const [lockedRoute, setLockedRoute] = useState(false);

  const validateRoute = (routeToValidate: string) => {
    // Skip validation if it's the same as the current curation's route
    if (routeToValidate === curation.route) {
      return true;
    }
    
    // Check if route exists in other curations
    const isDuplicate = existingRoutes.includes(routeToValidate);
    // Ensure route is not empty and contains only valid URL characters
    const isValidFormat = /^[a-zA-Z0-9-_]+$/.test(routeToValidate);
    
    const isValid = !isDuplicate && isValidFormat && routeToValidate.length > 0;
    return isValid;
  };

  useEffect(() => {
    setIsRouteValid(validateRoute(route));
  }, [route]);

  const handleSave = () => {
    if (isRouteValid) {
      onSave({ ...curation, title, videoUrl, explainer, route, lockedRoute });
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-96">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">Edit Curation</h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Video URL</label>
          <input
            type="text"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Explainer</label>
          <textarea
            value={explainer}
            onChange={(e) => setExplainer(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Route</label>
          <div className="mt-1 relative flex">
            <input
              type="text"
              value={route}
              onChange={(e) => setRoute(e.target.value)}
              disabled={lockedRoute}
              className={`block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm
                ${isRouteValid 
                  ? 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500' 
                  : 'border-red-500 focus:ring-red-500 focus:border-red-500'
                } ${lockedRoute ? 'bg-gray-100' : ''}`}
            />
            <button
              onClick={() => setLockedRoute(!lockedRoute)}
              className="absolute inset-y-0 right-0 px-3 flex items-center"
              title="Lock Route, making harder to change"
              type="button"
            >
              {lockedRoute ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-gray-500">
                  <path fillRule="evenodd" d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z" clipRule="evenodd" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-gray-500">
                  <path d="M18 1.5c2.9 0 5.25 2.35 5.25 5.25v3.75a.75.75 0 01-1.5 0V6.75a3.75 3.75 0 10-7.5 0v3a3 3 0 013 3v6.75a3 3 0 01-3 3H3.75a3 3 0 01-3-3v-6.75a3 3 0 013-3h9v-3c0-2.9 2.35-5.25 5.25-5.25z" />
                </svg>
              )}
            </button>
          </div>
          {!isRouteValid && (
            <p className="mt-1 text-sm text-red-600">
              Route must be unique and contain only letters, numbers, hyphens, or underscores
            </p>
          )}
        </div>
        
        {route &&  isRouteValid && (
          <div className="mb-4">
            Valid routes:
            <ul className="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
              <li className="flex items-center">
                <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>
                /curation/{route}
              </li>
              <li className="flex items-center">
                <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>
                /{route}
              </li> 
            </ul>
          </div>
        )}

        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            disabled={!isRouteValid}
            className={`px-4 py-2 rounded-lg ${
              isRouteValid
                ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                : 'bg-gray-400 text-gray-200 cursor-not-allowed'
            }`}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
