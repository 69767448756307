
import React, { useState, useEffect } from 'react';
import { QuestionInput } from '../shared/QuestionInput';
import { LoadingButton } from '../shared/LoadingButton';
import { SummaryModal } from '../shared/SummaryModal';
import { motion } from 'framer-motion';
import { useAuth } from '../../contexts/AuthContext';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { generateStructuredInfo } from '../../lib/openai';
import { toast } from 'react-hot-toast';

export default function Vistage() {
  const { user, userData } = useAuth();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState<{ [key: number]: string }>({});
  const [isGenerating, setIsGenerating] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [summaryData, setSummaryData] = useState<{
    questions: Array<{ question: string; answer: string }>;
    summary: string;
  } | null>(null);
  const [theme, setTheme] = useState<any>(null);
  const [questions, setQuestions] = useState<Array<{ id: number; text: string }>>([]);

  useEffect(() => {
    const simpleQuestions = [
      {
        id: 1,
        text: "What went well?",
      },
      {
        id: 2,
        text: "What was your part in that?",
      },
    ];
    setQuestions(simpleQuestions);
    setTheme({
      systemPrompt:
        "Create an inspiring summary that highlights the user's achievements and their role in making them happen. Structure the response in markdown format with sections for their successes, personal contributions, who they interacted with to get these outcomes. Don't return a title and use ### for the sections. You can exclude sections if there isn't enough information to fill them out. Always end with a thank you for reflection on what went well and let them know to go through the questions as many times as they want, they more the better!",
    });
  }, []);

  const handleAnswerChange = (value: string) => {
    const currentQuestionId = questions[currentQuestion]?.id;
    if (currentQuestionId) {
      setAnswers((prev) => ({
        ...prev,
        [currentQuestionId]: value,
      }));
    }
  };

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
    }
  };

  const handleGenerateSummary = async () => {
    if (!theme || !questions.length) return;

    setIsGenerating(true);
    try {
      const content = questions
        .map((q) => `Question: ${q.text}\nAnswer: ${answers[q.id] || ""}`)
        .join("\n\n");

      const response = await generateStructuredInfo(
        content,
        { model: "gpt-4", temperature: 0.7 },
        theme.systemPrompt,
      );

      if (!response?.data) {
        throw new Error("Failed to generate summary");
      }

      const timestamp = new Date().toISOString();
      const completedTheme = {
        userId: user?.uid || 'anonymous',
        userName: userData
          ? `${userData.firstName} ${userData.lastName}`
          : "Anonymous",
        themeId: "quick-reflection",
        theme_name: "What went well",
        channel: "findingblueberries-vistage",
        responses: Object.entries(answers).map(([questionId, content]) => ({
          questionId: parseInt(questionId),
          question:
            questions.find((q) => q.id === parseInt(questionId))?.text || "",
          content,
          createdAt: timestamp,
        })),
        summary: response.data,
        completedAt: timestamp,
      };

      await addDoc(collection(db, "completedThemes"), completedTheme);

      setSummaryData({
        questions: questions.map((q) => ({
          question: q.text,
          answer: answers[q.id] || "",
        })),
        summary: response.data,
      });
      setShowSummaryModal(true);
    } catch (error: any) {
      console.error("Error generating summary:", error);
      toast.error(error.message || "Failed to generate summary");
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-sky-100 to-indigo-50">
      <div className="flex flex-col items-center justify-center min-h-[75vh] p-4">
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="text-center max-w-4xl w-full"
        >
          <h1 className="text-4xl md:text-5xl font-bold text-indigo-900 mb-4">
            Welcome Vistage members!
          </h1>
          <p className="text-lg text-indigo-700 mb-8">
            Finding Blueberries Teaser
          </p>

          <div className="flex flex-col items-center space-y-6 max-w-2xl mx-auto">
            <div className="w-full bg-white rounded-xl shadow-lg p-8">
              <h2 className="text-2xl font-semibold text-indigo-900 mb-6">
                Quick Reflection
              </h2>
              {questions.length > 0 && (
                <div className="space-y-4">
                  <QuestionInput
                    question={questions[currentQuestion]?.text || ""}
                    value={answers[questions[currentQuestion]?.id] || ""}
                    onChange={handleAnswerChange}
                    placeholder="Type your answer here..."
                  />
                  <div className="flex justify-end w-full">
                    <LoadingButton
                      isLoading={isGenerating}
                      onClick={
                        currentQuestion < questions.length - 1
                          ? handleNext
                          : handleGenerateSummary
                      }
                      disabled={!answers[questions[currentQuestion]?.id]}
                      showArrow={true}
                    >
                      {currentQuestion < questions.length - 1
                        ? "Next Question"
                        : "Generate Summary"}
                    </LoadingButton>
                  </div>
                </div>
              )}
            </div>

            {showSummaryModal && summaryData && (
              <SummaryModal
                isVisible={showSummaryModal}
                onClose={() => setShowSummaryModal(false)}
                summaryData={summaryData}
              />
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
}
