import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { doc, updateDoc, collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { X } from 'lucide-react';
import SubscriptionDetailView from '../Admin/SubscriptionDetailView';
import { SubscriptionService, FirebaseSubscriptionRepository, Subscription } from '../../lib/dal/subscriptions';

/*

-- Show subscription details
-- should what groups the are in, and what their role is in the group.
-- show group subscription details is they are the group admin.
-- if they have multiple groups, show which subscription their user is paid by. 


*/

export default function Profile() {
  const navigate = useNavigate();
  const { userData, user, dispatch } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState(userData?.firstName || '');
  const [lastName, setLastName] = useState(userData?.lastName || '');
  const [email, setEmail] = useState(userData?.email || '');
  const [showAnswers, setShowAnswers] = useState(false);
  const [connectorTypeAnswers, setConnectorTypeAnswers] = useState<Array<{question: string, content: string}>>([]);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription | null>(null);
  const subscriptionService = new SubscriptionService(new FirebaseSubscriptionRepository());

  useEffect(() => {
    const fetchAnswers = async () => {
      if (!user) return;
      const answersRef = collection(db, 'completedConnectorTypes');
      const q = query(answersRef, 
        where('userId', '==', user.uid),
        orderBy('completedAt', 'desc'),
        limit(1)
      );
      const snapshot = await getDocs(q);
      if (!snapshot.empty) {
        const doc = snapshot.docs[0];
        const data = doc.data();
        setConnectorTypeAnswers(data.questions.map((q: any) => ({
          question: q.text,
          content: q.answer
        })));
      }
    };

    const loadSubscriptions = async () => {
        console.log("Loading subscriptions for user", user.uid);
        // get subscriptions for admins
        //  OR
        // get users subscriptions
        const adminSubscriptions = await subscriptionService.getAdminSubscriptions(user.uid);
        const userSubscriptions = await subscriptionService.getSubscriptionsByIds(userData.subscription_ids);
        const subscriptions = [...new Set([...adminSubscriptions, ...userSubscriptions])];

        setSubscriptions(subscriptions);
        console.log("Subscriptions", JSON.stringify(subscriptions, null, 2));
        if (subscriptions.length > 0) {
            // find the subscription has paid_seats == 0
            const selfSubscription = subscriptions.find(sub => sub.paid_seats === 0);
            setSelectedSubscription(selfSubscription);
        } else {
          setSelectedSubscription(subscriptions[0]);
        }
    };

    loadSubscriptions();
    fetchAnswers();
  }, [user]);

  const handleSave = async () => {
    try {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        firstName,
        lastName,
        email
      });

      dispatch({
        type: 'UPDATE_USER_DATA',
        payload: { ...userData, firstName, lastName, email }
      });

      setIsEditing(false);
      toast.success('Profile updated successfully');
    } catch (error) {
      toast.error('Failed to update profile');
      console.error('Error updating profile:', error);
    }
  };

  const handleStartConnectorType = () => {
    navigate('/assessments/connector-type');
  };

  const handleManageSubscription = () => {
    navigate('/admin/subscription-admin');
  }

  return (
    <div className="w-full max-w-[1920px] mx-auto p-4 md:p-8">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white rounded-xl shadow-sm p-6 md:p-8">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-3xl font-bold text-indigo-900">Profile</h1>
            {!isEditing ? (
              <button
                onClick={() => setIsEditing(true)}
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                Edit Profile
              </button>
            ) : (
              <div className="space-x-4">
                <button
                  onClick={() => setIsEditing(false)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSave}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                >
                  Save Changes
                </button>
              </div>
            )}
          </div>

          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">First Name</label>
                {isEditing ? (
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-lg"
                  />
                ) : (
                  <p className="text-gray-900">{userData?.firstName}</p>
                )}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Last Name</label>
                {isEditing ? (
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-lg"
                  />
                ) : (
                  <p className="text-gray-900">{userData?.lastName}</p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Email</label>
              {isEditing ? (
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-lg"
                />
              ) : (
                <p className="text-gray-900">{userData?.email}</p>
              )}
            </div>

            {userData?.connector_type ? (
              <div className="mb-8 p-6 bg-gradient-to-r from-indigo-50 to-purple-50 rounded-xl">
                <h2 className="text-xl font-semibold text-indigo-900 mb-4">Your Connector Type</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div className="p-6 bg-white rounded-lg shadow-sm">
                    <p className="text-sm text-gray-600 mb-1">Primary</p>
                    <p className="text-xl font-semibold text-indigo-900">{userData.connector_type.primary}</p>
                  </div>
                  <div className="p-6 bg-white rounded-lg shadow-sm">
                    <p className="text-sm text-gray-600 mb-1">Secondary</p>
                    <p className="text-xl font-semibold text-purple-900">{userData.connector_type.secondary}</p>
                  </div>
                </div>
                <div className="flex justify-end space-x-4 mt-4 text-sm">
                  <button
                    onClick={handleStartConnectorType}
                    className="text-indigo-600 hover:text-indigo-800"
                  >
                    Retake Assessment
                  </button>
                  <button
                    onClick={() => setShowAnswers(true)}
                    className="text-purple-600 hover:text-purple-800"
                  >
                    View Answers
                  </button>
                </div>
              </div>
            ) : (
              <div className="mb-8 p-6 bg-gradient-to-r from-indigo-50 to-purple-50 rounded-xl">
                <h2 className="text-xl font-semibold text-indigo-900 mb-4">Discover Your Connector Type</h2>
                <p className="text-gray-600 mb-4">
                  Take a quick assessment to understand your unique way of connecting with others.
                </p>
                <button
                  onClick={handleStartConnectorType}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                >
                  Start Assessment
                </button>
              </div>
            )}

            {showAnswers && (
              <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
                <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto p-6">
                  <div className="flex justify-between items-center mb-6">
                    <div>
                      <h2 className="text-xl font-semibold text-indigo-900">Your Connector Type Responses</h2>
                      <p className="text-sm text-gray-600 mt-1">
                        Completed on {new Date(userData.connector_type.completedAt).toLocaleDateString()}
                      </p>
                    </div>
                    <button 
                      onClick={() => setShowAnswers(false)}
                      className="text-gray-400 hover:text-gray-600"
                    >
                      <X className="w-5 h-5" />
                    </button>
                  </div>
                  <div className="space-y-6">
                    {connectorTypeAnswers.map((answer, index) => (
                      <div key={index} className="border-b border-gray-100 pb-4">
                        <p className="font-medium text-gray-900 mb-2">{answer.question}</p>
                        <p className="text-gray-600">{answer.content}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

         <SubscriptionDetailView
            subscriptions={subscriptions || []}
            selectedSubscription={subscriptions[0]}
            onSubscriptionSelect={(subscription) => {
              console.log("Selected subscription", subscription);
            }}
          />
          { (subscriptions.length > 1 || ( selectedSubscription && (selectedSubscription.paid_seats > 1 || selectedSubscription.cerified_seats))) && (
            <div>
              <button 
                onClick={handleManageSubscription}
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                Manage All Subscriptions
              </button>
            </div>
          )}
          </div>
        </div>
      </div>
    </div>
  );
}