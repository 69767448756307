import { 
    collection, 
    query,
    Firestore, 
    CollectionReference, 
    DocumentData, 
    updateDoc,
    doc, 
    getDoc, 
    orderBy,
    getDocs,
    where,
    addDoc
} from 'firebase/firestore';
import { db } from '../firebase';
import { Duration } from '../../enums/PurchaseEnums';

export enum SubscriptionSource {
    STRIPE = 'stripe',
    CERTIFICATION = 'certification'
}

export interface PricePlan {
    planID: string; //stripeSub.plan.id
    planName: string;
    price: number;  //stripeSub.plan.amount
    duration: Duration; //Duration
    description: string;  //`${quananity} seats at ${price} per ${duration}`
}

/*
  "plan": {
    "id": "price_1QlYkDE9yRcx9fqtxoT8XXBD",
    "object": "plan",
    "active": true,
    "aggregate_usage": null,
    "amount": 8000,
    "amount_decimal": "8000",
    "billing_scheme": "per_unit",
    "created": 1737909093,
    "currency": "usd",
    "interval": "month",
    "interval_count": 12,
    */

export enum RenewalStatus {
    CANCELED = 'canceled',
    ACTIVE = 'active'
}

export enum TransactionType {
    CHARGE = 'charge',
    REFUND = 'refund'
}

export interface Transaction {
    date: string;
    type: TransactionType;
    amount: number;
    description: string;
}

export interface SubscriptionMember {
    user_id: string;
    team?: string;
}


export interface Subscription {
    id?: string;
    external_subscription_id?: string;
    subscription_source: SubscriptionSource;
    external_customer_id: string;
    price_plan: PricePlan;
    start_date: string;
    end_date: string;
    paid_seats: number;
    certified_seats: number;
    members: SubscriptionMember[];
    admin_id?: string;
    renewal_status: RenewalStatus;
    history: Transaction[];
    code: string;
}

const SUBSCRIPTIONTABLE = 'subscriptions';


const generateSubscriptionCode = async (): Promise<string> => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    let isUnique = false;

    while (!isUnique) {
        result = '';
        for (let i = 0; i < 5; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
        }
        isUnique = await verifySubscriptionCodeUnique(result);
    }

    return result;
};

const verifySubscriptionCodeUnique = async (code: string): Promise<boolean> => {
    const groupsRef = collection(db, SUBSCRIPTIONTABLE);
    const q = query(groupsRef, where('code', '==', code));
    const snapshot = await getDocs(q);
    return snapshot.empty;
};


export interface ISubscriptionRepository {
    getSubscription: (subscriptionId: string) => Promise<Subscription>;
    getAdminSubscriptions: (userId: string) => Promise<Subscription[]>;
    getSubscriptionsByIds: (subscription_ids: string[]) => Promise<Subscription[]>;
    addMember: (subscriptionId: string, member: SubscriptionMember) => Promise<void>;
    removeMember: (subscriptionId: string, memberId: string) => Promise<void>;
    createSubscription: (subscription: Subscription) => Promise<Subscription>;
    updateSubscription: (subscription: Subscription) => Promise<Subscription>;
    deleteSubscription: (subscriptionId: string) => Promise<void>;
}


export class MockSubscriptionRepository implements ISubscriptionRepository {

    async getSubscription(subscriptionId: string): Promise<Subscription> {
        console.log('getSubscription', subscriptionId);
        return Promise.resolve({} as Subscription);
    }

    async getAdminSubscriptions(userId: string): Promise<Subscription[]> {
        console.log('getSubscriptions for user: ', userId);
        return Promise.resolve([]);
    }

    async getSubscriptionsByIds(subscription_ids: string[]): Promise<Subscription[]> {
        console.log('getSubscriptionsByIds', subscription_ids);
        return Promise.resolve([]);
    }

    async createSubscription(subscription: Subscription): Promise<Subscription> {
        console.log(`createSubscription: ${JSON.stringify(subscription)}`);
        return Promise.resolve(subscription);
    }

    async updateSubscription(subscription: Subscription): Promise<Subscription> {
        console.log('updateSubscription', subscription);
        return Promise.resolve(subscription);
    }

    async deleteSubscription(subscriptionId: string): Promise<void> {
        console.log('deleteSubscription', subscriptionId);
    }

    async addMember(subscriptionId: string, member: SubscriptionMember): Promise<void> {
        console.log('addMember', subscriptionId, member.user_id);
    }

    async removeMember(subscriptionId: string, memberId: string): Promise<void> {
        console.log('removeMember', subscriptionId, memberId);
    }
}


export class FirebaseSubscriptionRepository implements ISubscriptionRepository {
    private db: Firestore;
    private collection: CollectionReference<DocumentData>;

    constructor() {
        this.db = db;
        this.collection = collection(this.db, SUBSCRIPTIONTABLE);
    }

    async getSubscription(subscriptionId: string): Promise<Subscription> {
        const subscriptionDoc = await getDoc(doc(this.collection, subscriptionId));
        return subscriptionDoc.data() as Subscription;
    }

    async getAdminSubscriptions(userId: string): Promise<Subscription[]> {
        try {
            const q = query(
              this.collection,
              where("admin_id", "==", userId)
            );
            
            const snapshot = await getDocs(q);
            console.log(`Found ${snapshot.size} subscriptions for admin ${userId}`);
            
            return snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            } as Subscription));
          } catch (error) {
            console.error('Error fetching admin subscriptions:', error);
            throw error;
          }
    }

    async getSubscriptionsByIds(subscription_ids: string[]): Promise<Subscription[]> {
        try {
            const q = query(
                this.collection,
                where('__name__', 'in', subscription_ids)
            );
            const snapshot = await getDocs(q);
            console.log(`Found ${snapshot.size} subscriptions`);
            
            return snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            } as Subscription));
        } catch (error) {
            console.error('Error fetching subscriptions by ids:', error);
            throw error;
        }
    }

    async addMember(subscriptionId: string, member: SubscriptionMember): Promise<void> {

        let subscription: Subscription;
        try {
            subscription = await this.getSubscription(subscriptionId);
        } catch (error) {   
            console.error('Error adding member:', error);
            throw error;
        }

        if (!subscription) {
            throw new Error('Subscription not found');
        }
        if (!subscription.members) {
            subscription.members = [];
        }
        //is expired subscription
        if (new Date(subscription.end_date) < new Date()) {
            throw new Error('Subscription is expired');
        }
        const seatsUsed = subscription.members.length;
        const availableSeats = subscription.paid_seats + subscription.certified_seats - seatsUsed;  
        if (availableSeats > 0) {
            subscription.members.push(member);
        } else {
            throw new Error('Subscription is full');
        }

        await this.updateSubscription({...subscription, id: subscriptionId});
    }

    async removeMember(subscriptionId: string, memberId: string): Promise<void> {
        try {
            const docRef = doc(this.collection, subscriptionId);
            const subscription = await getDoc(docRef);
            
            if (!subscription.exists()) {
                throw new Error('Subscription not found');
            }

            const currentData = subscription.data();
            const updatedMembers = currentData.members.filter(
                (member: SubscriptionMember) => member.user_id !== memberId
            );

            // Use a more specific update that only touches the members array
            await updateDoc(docRef, {
                members: updatedMembers
            }).catch(error => {
                // Log the error but don't throw if the update was successful
                console.warn('Warning: Update notification failed but data was updated:', error);
            });
        } catch (error) {   
            console.error('Error removing member:', error);
            // Only throw if it's not a permission error on the notification
            if (error.message !== 'Missing or insufficient permissions.') {
                throw error;
            }
        }
    }

    async createSubscription(subscription: Subscription): Promise<Subscription> {
        const code = await generateSubscriptionCode();
        subscription.code = code;
        const newSubscriptionRef = await addDoc(this.collection, subscription);
        return { ...subscription, id: newSubscriptionRef.id };
    }

    async updateSubscription(subscription: Subscription): Promise<Subscription> {
        const { id, ...updateData } = subscription;
        await updateDoc(
            doc(this.collection, subscription.id),
            updateData
        );
        return subscription;
    }

    async deleteSubscription(subscriptionId: string): Promise<void> {
        await updateDoc(doc(this.collection, subscriptionId), { deleted: true });
    }
}


export class SubscriptionService {
    private repository: ISubscriptionRepository;

    constructor(repository: ISubscriptionRepository) {
        this.repository = repository;
    }

    async getSubscription(subscriptionId: string): Promise<Subscription> {
        return this.repository.getSubscription(subscriptionId);
    }

    async addMember(subscriptionId: string, member: SubscriptionMember): Promise<void> {
        return this.repository.addMember(subscriptionId, member);
    }

    async removeMember(subscriptionId: string, memberId: string): Promise<void> { 
        return this.repository.removeMember(subscriptionId, memberId);
    }

    async getAdminSubscriptions(userId: string): Promise<Subscription[]> {
        return this.repository.getAdminSubscriptions(userId);
    }

    async getSubscriptionsByIds(subscription_ids: string[]): Promise<Subscription[]> {
        return this.repository.getSubscriptionsByIds(subscription_ids);
    }   

    async createSubscription(subscription: Subscription): Promise<Subscription> {
        return this.repository.createSubscription(subscription);
    }

    async updateSubscription(subscription: Subscription): Promise<Subscription> {
        return this.repository.updateSubscription(subscription);
    }

    async deleteSubscription(subscriptionId: string): Promise<void> {
        return this.repository.deleteSubscription(subscriptionId);
    }
}