import { useState, useEffect, useMemo } from 'react';
import { collection, query, where, orderBy, getDocs, doc, updateDoc, addDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { GripHorizontal, Plus, Settings } from 'lucide-react';
import { toast } from 'react-hot-toast';
import type { Experience, Curation } from '../../lib/firebase/types';
import CurationSelector from './CurationSelector';
import EditCurationModal from './EditCurationModal';

enum ToggleActions {
    Add = 'add',
    Remove = 'remove'
}

export default function Curations() {
  const [experiences, setExperiences] = useState<Experience[]>([]);
  const [curations, setCurations] = useState<Curation[]>([]);
  const [currentCuration, setCurrentCuration] = useState<Curation | null>(null);
  const [loading, setLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currationRoutes, setCurrationRoutes] = useState<string[]>([]);

  useEffect(() => {
    fetchCurations();
    fetchExperiences();
  }, []);


  const curationExperiences = useMemo(() => {
    if (!currentCuration?.experienceIds) return [];
    return experiences.filter((e) => currentCuration.experienceIds.includes(e.id));
  }, [currentCuration, experiences]);
  

  const otherExperiences = useMemo(() => {
    if (!currentCuration?.experienceIds) 
        return experiences;
    const otherExperiences = experiences
      .filter((e) => !currentCuration.experienceIds.includes(e.id))
      .sort((a, b) => a.title.localeCompare(b.title));
    return otherExperiences;
  }, [currentCuration, experiences]);


  const fetchCurations = async () => {
    try {
      setLoading(true);
      const q = query(
        collection(db, 'curations'),
        orderBy('title', 'asc')
      );
      
      const snapshot = await getDocs(q);
      const curationsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Curation[];

      setCurrationRoutes(curationsData.map(c => c.route)); 
      setCurations(curationsData);
      setCurrentCuration(curationsData[0] || null);
    } catch (error) {
      console.error('Error fetching curations:', error);
      toast.error('Failed to load curations');
    } finally {
      setLoading(false);
    }
  };

  const addCuration = async () => {
    try {
        const title = `New Curation ${new Date().toISOString().split('T')[0]}`;
        const newCuration = {
            title: title,
            experienceIds: [],
            videoUrl: '',
            explainer: ''
        };

        const docRef = await addDoc(collection(db, 'curations'), newCuration);
        const addedCuration = { ...newCuration, id: docRef.id };
        setCurations(prevCurations => [...prevCurations, addedCuration]);
        setCurrentCuration(addedCuration);
        toast.success('Curation added successfully');
    } catch (error) {
        console.error('Error adding curation:', error);
        toast.error('Failed to add curation');
    }
  }

  const fetchExperiences = async () => {
    try {
      setLoading(true);
      const q = query(
        collection(db, 'experiences'),
        where('isActive', '==', true),
        orderBy('order', 'asc')
      );
      
      const snapshot = await getDocs(q);
      const experiencesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Experience[];
      
      setExperiences(experiencesData);
    } catch (error) {
      console.error('Error fetching experiences:', error);
      toast.error('Failed to load experiences');
    } finally {
      setLoading(false);
    }
  };

  const handleToggleExperience = async (experience: Experience, action: ToggleActions ) => {
    try {
        if (!currentCuration) return;
        let updatedCuration = {...currentCuration};
        
        if (action === ToggleActions.Add) {
            updatedCuration = {...currentCuration, experienceIds: [...currentCuration.experienceIds, experience.id]};
        } else if (action === ToggleActions.Remove) {
            const newIdList = currentCuration?.experienceIds.filter(e => e !== experience.id);
            updatedCuration = {...currentCuration, experienceIds: newIdList};
        }

        setCurrentCuration(updatedCuration);
        const { id, ...updatedCurationWithoutId } = updatedCuration;
        const curationRef = doc(db, 'curations', id);
        await updateDoc(curationRef, updatedCuration);
    } catch (error) {
      console.error('Error updating experience:', error);
      toast.error('Failed to update experience');
    }
  };

  const handleReorder = async (draggedId: string, targetId: string) => {
    try {
        if (!currentCuration) return;
        const updatedExperienceIds = [...currentCuration.experienceIds];
        const drgIndex = currentCuration?.experienceIds.findIndex(e => e === draggedId);
        const tgtIndex = currentCuration?.experienceIds.findIndex(e => e === targetId);

        // Remove dragged item
        const [removed] = updatedExperienceIds.splice(drgIndex, 1);
        // Insert it at the target index
        updatedExperienceIds.splice(tgtIndex, 0, removed);

        const updatedCuration = {...currentCuration, experienceIds: updatedExperienceIds};
        const { id, ...updatedCurationWithoutId } = updatedCuration;
        const curationRef = doc(db, 'curations', id);
        await updateDoc(curationRef, updatedCuration);
        setCurrentCuration(updatedCuration);
    } catch (error) {
      console.error('Error reordering experiences:', error);
      toast.error('Failed to reorder experiences');
    }
  };

  const handleEditCuration = async (updatedCuration: Curation) => {
    if (currentCuration) {
      try {
        const { id, ...updatedCurationWithoutId } = updatedCuration;
        const curationRef = doc(db, 'curations', id);
        await updateDoc(curationRef, updatedCuration);
        setCurations(prevCurations => prevCurations.map(c => c.id === currentCuration.id ? updatedCuration : c));
        setCurrentCuration(updatedCuration);
        toast.success('Curation updated successfully');
      } catch (error) {
        console.error('Error updating curation:', error);
        toast.error('Failed to update curation');
      }
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-sm rounded-lg overflow-hidden">
      <div className="p-6 border-b border-gray-200">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-900">Manage Curations</h2>
          <button
            onClick={addCuration}
            className="flex items-center space-x-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
          >
            <Plus className="w-4 h-4" />
            <span>Add Curation</span>
          </button>
        </div>
        <div className="flex items-center justify-between">
            <div className="flex-1 flex items-center justify-between space-x-4">
                <div className="min-w-124">
                    <CurationSelector 
                        curations={curations} 
                        selectedCuration={currentCuration}
                        onCurationSelect={setCurrentCuration} 
                        onEdit={() => setShowEditModal(true)}
                    />
                </div>
            </div>
        </div>
        
        {currentCuration && (
            <div className="mt-6 p-6 bg-gradient-to-br from-indigo-50 to-white rounded-lg shadow-sm">
                <div className="max-w-3xl mx-auto grid grid-cols-3 gap-6">
                    <div className="col-span-2 flex flex-col h-full">
                        <div className="flex items-start justify-between mb-4">
                            <h2 className="text-2xl font-bold text-gray-900">
                                {currentCuration.title}
                            </h2>
                            <button
                                onClick={() => setShowEditModal(true)}
                                className="p-1 text-gray-500 hover:text-gray-700 transition-colors"
                                title="Edit curation"
                            >
                                <Settings className="w-5 h-5" />
                            </button>
                        </div>
                        
                        {currentCuration.explainer && (
                            <div className="prose prose-indigo max-w-none mb-8">
                                <p className="text-gray-700 leading-relaxed">
                                    {currentCuration.explainer}
                                </p>
                            </div>
                        )}

                        {currentCuration?.route && (
                            <div className="mt-auto pt-4">
                                Route:
                                <a 
                                    href={`/curation/${currentCuration.route}`} 
                                    target='_blank' 
                                    rel='noreferrer' 
                                    className='text-indigo-600 hover:underline whitespace-nowrap ml-1'
                                >
                                    /curation/{currentCuration.route}
                                </a>
                            </div>
                        )}
                    </div>
                    
                    {currentCuration.videoUrl && (
                        <div className="col-span-1">
                            <div className="relative aspect-video rounded-lg overflow-hidden shadow-lg">
                                <iframe
                                    src={currentCuration.videoUrl}
                                    className="absolute top-0 left-0 w-full h-full"
                                    title="YouTube video"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )}

        <p className="mt-4 text-gray-600">
          Select which experiences to show on the Curation page and arrange their order.
        </p>
      </div>

      {currentCuration && (
        <div className="p-6">
          <div className="mb-8">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Current Experiences in Curation</h3>
            <div className="space-y-2">
              {curationExperiences.length === 0 ? (
                <p className="text-gray-500 italic">No experiences selected for this Curation</p>
              ) : (
                currentCuration.experienceIds.map((experienceId) => {
                  const experience = experiences.find(e => e.id === experienceId);
                  if (!experience) return null;
                    
                  return (  
                  <div
                    key={experienceId}
                    className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
                    draggable
                    onDragStart={(e) => e.dataTransfer.setData('text/plain', experienceId)}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => {
                      e.preventDefault();
                      const draggedId = e.dataTransfer.getData('text/plain');
                      handleReorder(draggedId, experienceId);
                    }}
                  >
                    <div className="flex items-center space-x-4">
                      <div>
                        <GripHorizontal className="w-5 h-5 text-gray-400 cursor-move" />
                        <h4 className="font-medium text-gray-900">{experience.title}</h4>
                        <p className="text-sm text-gray-500">{experience.description}</p>
                      </div>
                    </div>
                    <button
                      onClick={() => handleToggleExperience(experience, ToggleActions.Remove)}
                      className="px-3 py-1 text-sm text-red-600 hover:text-red-700 hover:bg-red-50 rounded-lg"
                    >
                      Remove
                    </button>
                  </div>
                  )
})
              )}
            </div>
          </div>

          <div>
            <h3 className="text-lg font-medium text-gray-900 mb-4">All Active Experiences</h3>
            <div className="space-y-2">
              {otherExperiences.map((experience) => (
                <div
                  key={experience.id}
                  className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
                >
                  <div>
                    <h4 className="font-medium text-gray-900">{experience.title}</h4>
                    <p className="text-sm text-gray-500">{experience.description}</p>
                  </div>
                  <button
                    onClick={() => handleToggleExperience(experience, ToggleActions.Add)}
                    className="px-3 py-1 text-sm text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg"
                  >
                    Add to Deep Dives
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {showEditModal && currentCuration && (
        <EditCurationModal
          curation={currentCuration}
          onClose={() => setShowEditModal(false)}
          onSave={handleEditCuration}
          existingRoutes={currationRoutes}
        />
      )}
    </div>
  );
}
