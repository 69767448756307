import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { auth } from "../../lib/firebase";
import { Users, UserPlus, UserMinus, Calendar, CreditCard, Clock } from "lucide-react";
import AddSubscriptionMemberModal from "./AddSubscriptionMemberModal";
import SubscriptionSelector from "./SubscriptionSelector";
import { UserService, FirebaseUserRepository } from "../../lib/dal/users";
import { FirebaseSubscriptionRepository, SubscriptionService, Subscription } from '../../lib/dal/subscriptions';
import MemberTable from "./MemberTable";
import { AccessLevel } from "../../enums/AccessLevel";
import toast from "react-hot-toast";
import SubscriptionDetailView from './SubscriptionDetailView';

interface Member {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  team: string;
}


const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

const formatPrice = (price: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(price);
};

export default function SubscriptionAdmin() {
    const subscriptionService = new SubscriptionService(new FirebaseSubscriptionRepository());
    const userService = new UserService(new FirebaseUserRepository());
    const { user, userData, dispatch } = useAuth();
    const [subscriptions, setSubscriptions] = useState([]);
    const [selectedSubscription, setSelectedSubscription] = useState<Subscription | null>(null);
    const [showAddMemberModal, setShowAddMemberModal] = useState(false);
    const [totalSeats, setTotalSeats] = useState(0);
    const [availableSeats, setAvailableSeats] = useState(0);
    const [memberData, setMemberData] = useState<Member[]>([]);
    const [adminNotMember, setAdminNotMember] = useState(false);

  useEffect(() => {
    const loadSubscriptions = async () => {
        //console.log("Loading subscriptions for user", user.uid);
        const subscriptions = await subscriptionService.getSubscriptions(user.uid);
        setSubscriptions(subscriptions);
        if (subscriptions.length == 1) {
            setSelectedSubscription(subscriptions[0]);
        }
    };
    loadSubscriptions();
  }, []);

  // compute Subscription data
  useEffect(() => {
    //console.log("Selected subscription: ", selectedSubscription);
    if (!selectedSubscription) return;
    //console.log("Computing");
    const totalNumberSeats = selectedSubscription?.paid_seats + selectedSubscription?.certified_seats;
    setTotalSeats(totalNumberSeats);
    setAvailableSeats(totalNumberSeats - selectedSubscription?.members?.length);

    const loadMembers = async () => {
      const memberIds = selectedSubscription?.members.map(member => member.user_id);
      if (!memberIds || memberIds.length < 1) return;
      //console.log("Member IDs: ", memberIds);

      // Create map of user_id to team
      const teamMap = selectedSubscription?.members.reduce((acc, member) => {
        acc[member.user_id] = member.team || '';
        return acc;
      }, {} as Record<string, string>);
      //console.log("Team Map: ", teamMap);

      let memberData = [];
      const users = await userService.getUsers(memberIds);
      memberData = users.map(user => ({
        ...user,
        team: teamMap[user.id] || 'default'
      } as Member));
      //console.log("Member Data: ", memberData);
      setMemberData(memberData);
    };
    loadMembers();

    //if current user is not a member set a flag 
    if (!selectedSubscription.members.find(member => member.user_id === user.uid)) {
      setAdminNotMember(true);
    }

      // setMemberData(members);
  }, [selectedSubscription]);

  const handleRemoveMember = async (userId: string) => {
    //console.log("Removing member: ", userId); 

    try {
      const fbbUser = await userService.getUser(userId);
      if (!fbbUser) {
        console.error('User not found');
        toast.error('User not found in the system.');
        return;
      }
     
      // Only update required fields
      const updates = {
        subscription_ids: fbbUser.subscription_ids.filter(subId => subId !== selectedSubscription.id)
      };
      setMemberData(memberData.filter(member => member.id !== userId));
      if (fbbUser.access_level === AccessLevel.PersonalMembership && updates.subscription_ids.length === 1) {
        updates.access_level = AccessLevel.Free;
        dispatch({ type: "UPDATE_ACCESS_LEVEL", payload: AccessLevel.Free });
      }
      await userService.updateUser({...updates, id: userId});

      await subscriptionService.removeMember(selectedSubscription.id, userId);

    } catch (error) { 
      console.error('Error removing member:', error);
      toast.error('Error removing member');
    }
    setAvailableSeats(availableSeats + 1);
  };

  const handleAddAdminMember = async () => {  
    //console.log("Adding admin as member: ", user.uid);
    try {
      await subscriptionService.addMember(selectedSubscription.id, { user_id: user.uid, team: 'default'});
      setMemberData([...memberData, { id: user.uid, email: userData.email, firstName: userData.firstName, lastName: userData.lastName, team: 'default' }]);
      if (userData?.access_level === AccessLevel.Free) {
        const updatedUser = { ...userData, id: user?.uid, access_level: AccessLevel.PersonalMembership };
        await userService.updateUser(updatedUser);
        dispatch({ type: "UPDATE_ACCESS_LEVEL", payload: AccessLevel.PersonalMembership });
      }
      setAdminNotMember(false);
    } catch (error) { 
      console.error('Error adding member:', error);
      toast.error('Error adding member');
    }
  };

  const handleModalClose = (newUser?: Member) => {
    if (newUser) {
      //console.log("Adding new user: ", newUser);
      handleAddMember(newUser);
    }
    setShowAddMemberModal(false);
  };

  const handleAddMember = (member?: Member): void => {
    //console.log("Adding member: ", member);
    const newMemberData = [...memberData, member];
    //console.log("Post Add Member Data: ", newMemberData);
    setMemberData([...memberData, member]);
    setAvailableSeats(availableSeats - 1);
  }


  return (
    <div className="bg-white shadow-sm rounded-lg p-6 space-y-6">
      <SubscriptionDetailView
        subscriptions={subscriptions}
        selectedSubscription={selectedSubscription}
        onSubscriptionSelect={(subscription) => {
          setSelectedSubscription(subscription);
          //console.log("Selected subscription", subscription);
        }}
      />

      {selectedSubscription && (
        <div className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="bg-indigo-50 p-4 rounded-lg">
              <div className="flex items-center space-x-2 text-indigo-600 mb-2">
                <Users className="w-5 h-5" />
                <span className="font-medium">Total Seats</span>
              </div>
              <p className="text-2xl font-bold text-indigo-900">
                {totalSeats}
              </p>
            </div>

            <div className="bg-green-50 p-4 rounded-lg">
              <div className="flex items-center space-x-2 text-green-600 mb-2">
                <UserPlus className="w-5 h-5" />
                <span className="font-medium">Available Seats</span>
              </div>
              <p className="text-2xl font-bold text-green-900">
                {availableSeats}
              </p>
            </div>

            <div className="bg-purple-50 p-4 rounded-lg">
              <div className="flex items-center space-x-2 text-purple-600 mb-2">
                <span className="font-medium">Group Code</span>
              </div>
              <p className="text-2xl font-bold text-purple-900">
                {selectedSubscription?.code || "No Code" } 
              </p>
            </div>
          </div>

          <div>
            <div className="flex flex-row space-x-4 mb-4">
              <button
                onClick={() => setShowAddMemberModal(true)}
                disabled={availableSeats === 0}
                className="flex items-center space-x-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50"
              >
                <UserPlus className="w-4 h-4" />
                <span>Add Member</span>
              </button>
              { adminNotMember && (
              <button
                onClick={handleAddAdminMember}
                disabled={availableSeats === 0}
                className="flex items-center space-x-2 px-4 py-2 bg-orange-400 text-white rounded-lg hover:bg-orange-500 disabled:opacity-50"
              >
                <UserPlus className="w-4 h-4" />
                <span>Add Yourself as a Member</span>
              </button>
              )}
            </div>

            <MemberTable
              members={memberData}
              onRemoveMember={handleRemoveMember}
            />
          </div>
        </div>
      )}

      {showAddMemberModal && selectedSubscription && (
        <AddSubscriptionMemberModal
          subscription={selectedSubscription}
          onClose={handleModalClose}
        />
      )}
    </div>
  );
}
