import React, { useState, useMemo, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../contexts/AuthContext';
import { PurchaseType, Duration } from '../enums/PurchaseEnums';
import { AccessLevel } from '../enums/AccessLevel';
import { UserService, FirebaseUserRepository, MockUserRepository, FbbUser } from '../lib/dal/users';

interface BuyIndividualCardProps {
    duration: Duration;
    purchaseType: PurchaseType;
    setPurchaseType: (type: PurchaseType) => void;
    onCheckout: (type: PurchaseType, email: string) => void;
    monthlyPrice: number;
    annualPrice: number;
}

export default function BuyIndividualCard({ 
    duration, 
    purchaseType, 
    setPurchaseType, 
    onCheckout,
    monthlyPrice,
    annualPrice
}: BuyIndividualCardProps) {
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const { user, userData } = useAuth();

    const unableToPurchase = useMemo(() => {
        if (!user || !userData) return false;
        return userData.access_level !== AccessLevel.Free;
      }, [user, userData]);

    useEffect(() => {
        if (user && userData) {
            setEmail(userData.email);
            setValidEmail(true);
        }
    }, [user, userData]);

    const checkValidEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        if (checkValidEmail(newEmail)) {
            const usersRef = collection(db, 'users');
            const q = query(usersRef, where('email', '==', newEmail.toLowerCase()));
            const snapshot = await getDocs(q);

            if (!snapshot.empty) {
                setValidEmail(false);
                toast.error("This email is already in the system. Please login to upgrade.");
            } else {
                setValidEmail(true);
            }
        }
    };


    return (
        <motion.div
            whileHover={{ scale: 1.02 }}
            className={`bg-white rounded-2xl shadow-xl overflow-hidden cursor-pointer transition-all relative ${
                purchaseType === PurchaseType.INDIVIDUAL ? 'ring-2 ring-indigo-500' : ''
            }`}
            onClick={() => setPurchaseType(PurchaseType.INDIVIDUAL)}
        >
            {unableToPurchase && (
                <div className="absolute inset-0 bg-black/50 flex items-center justify-center z-10">
                    <p className="text-white text-xl font-semibold text-center px-4">
                        Sign out to create a new account.
                    </p>
                </div>
            )}
            <div className="p-8">
                <h2 className="text-2xl font-semibold text-gray-900 mb-4">Individual Plan</h2>
                <p className="text-gray-600 mb-6">
                    Access personalized insights to help you grow, connect, and thrive
                </p>
                <div className="text-4xl font-bold text-indigo-600 mb-4">
                    ${duration === Duration.MONTHLY ? monthlyPrice : annualPrice}
                    <span className="text-lg font-normal text-gray-500">
                        /{duration === Duration.MONTHLY ? 'mo' : 'year'}
                    </span>
                </div>
                <div className="mb-6">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Email
                    </label>
                    <input
                        id="email"
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                    />
                </div>
                <motion.button
                    onClick={(e) => {
                        e.stopPropagation();
                        onCheckout(PurchaseType.INDIVIDUAL, email);
                    }}
                    disabled={!validEmail}
                    onHoverStart={() => {if(!validEmail) {toast.error("Please enter a valid email address.")}}}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className={`w-full mb-6 px-6 py-3 rounded-lg transition-colors ${
                        !validEmail ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-600 text-white hover:bg-indigo-700'
                    }`}
                >
                    Purchase Individual Plan
                </motion.button>
                <ul className="space-y-4 mb-8">
                    {['Unlimited access to reflections', 'AI-powered insights', 'Export and share'].map((feature) => (
                        <li key={feature} className="flex items-center">
                            <Check className="h-5 w-5 text-green-500 mr-3" />
                            <span className="text-gray-600">{feature}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </motion.div>
    );
}
