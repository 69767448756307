import React, { useEffect, useRef, useState } from "react";
import { QuestionInput } from "./shared/QuestionInput";
import { LoadingButton } from "./shared/LoadingButton";
import { SummaryModal } from "./shared/SummaryModal";
import { AccessLevel } from "../enums/AccessLevel";
import { motion } from "framer-motion";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ArrowRight, Route, Users, CalendarDays, MapPin, BookOpen, LayoutDashboard } from "lucide-react";
import { useAuth } from "../contexts/AuthContext";
import SuccessBanner from "./shared/SuccessBanner";
import {
  doc,
  updateDoc,
  addDoc,
  collection,
  arrayUnion,
  getDocs,
  query,
  where,
  getDoc,
} from "firebase/firestore";
import { db } from "../lib/firebase";
import { sleep } from "openai/core.mjs";
import { generateStructuredInfo } from "../lib/openai";
import LandingContentGuestView from "./LandingContentGuestView";
import TestHome from "./TestHome";
import ThemeSummaryViewer from "./shared/ThemeSummaryViewer";

export default function Landing() {
  const showNewHome = true;
  const isMounted = useRef(false);
  const hasStartedOperation = useRef(false);

  const navigate = useNavigate();
  const { user, dispatch, userData } = useAuth();
  const [searchParams] = useSearchParams();
  const showSuccessBanner = searchParams.get("success") === "true";

  useEffect(() => {
    isMounted.current = true;

    if (
      searchParams.get("success") === "true" &&
      !hasStartedOperation.current
    ) {
      console.log(`Calling Handling linking user...`);
      if (user.uid) {
        handleLinkingUser(user.uid);  //firebaseAuth user ID
      } else {
        console.log('No user ID found to link.');
      }
      
    }

    return () => {
      isMounted.current = false;
    };
  }, [user, searchParams]); // Only re-run when user or searchParams change

  //TODO: don not allow group Admins to be deleted from the group members.
  const createGroup = async (
    userId: string,
    totalSeats: number,
    startDate: Date,
    endDate: Date,
    groupName: string,
  ) => {
    try {
      console.log("Creating group " + groupName);
      const groupCode = await generateGroupCode();
      const newGroup = {
        group_name: groupName,
        group_code: groupCode,
        total_seats: totalSeats,
        seats_used: 1, // Add Admin to group
        seats_remaining: totalSeats - 1, // Subtract 1 for the Admin
        members: [userId], // Add Admin to group
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        created_at: new Date().toISOString(),
        groupAdmin: userId,
      };

      return await addDoc(collection(db, "groups"), newGroup);
    } catch (error) {
      console.error("Error creating group:", error);
      return null;
    }
  };

  const updateUserDoc = async (userId: string, userInfo: any) => {
    console.log("Updating user document: " + userId);
    console.log("User Info: ", userInfo.stringify);
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, {
      access_level: userInfo.access_level,
      membership_start: userInfo.membership_start.toISOString(),
      membership_end: userInfo.membership_end.toISOString(),
      subscription_id: userInfo.subscription_id,
      stripe_customer_id: userInfo.stripe_customer_id,
    });

    if (userInfo.group_admin_for) {
      await updateDoc(doc(db, "users", userId), {
        group_admin_for: arrayUnion(userInfo.group_admin_for),
      });
    }
  };

  const generateGroupCode = async (): Promise<string> => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let result = "";
    let isUnique = false;

    while (!isUnique) {
      result = "";
      for (let i = 0; i < 5; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
      isUnique = await verifyGroupCodeUnique(result);
    }

    return result;
  };

  const verifyGroupCodeUnique = async (groupCode: string): Promise<boolean> => {
    const groupsRef = collection(db, "groups");
    const q = query(groupsRef, where("group_code", "==", groupCode));
    const snapshot = await getDocs(q);
    return snapshot.empty;
  };

  const getQuantityFromSession = (sessionParam: string | null): number => {
    if (!sessionParam) return 1;
    const quantity = parseInt(sessionParam, 10);
    return isNaN(quantity) ? 1 : quantity;
  };

  const handleLinkingUser = async (userId: string) => {
    if (hasStartedOperation.current) {
      console.log("Already processing");
      return;
    }
    if (!searchParams.get("success")) {
      console.log("no success param");
      return;
    }

    try {
      hasStartedOperation.current = true;
      console.log(
        `Processing new subscription... mounted:${isMounted.current} hasStarted:${hasStartedOperation.current}`,
      );
      const sessionID = searchParams.get("s");
      const accessLevel = searchParams.get("g")
        ? AccessLevel.GroupMembership
        : AccessLevel.PersonalMembership;
      const totalSeats = getQuantityFromSession(searchParams.get("q"));

      const response = await fetch("/api/handle-stripe-subscribe", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ sessionId: sessionID }),
      });

      if (!response.ok)
        throw new Error("Failed to process subscription: " + sessionID);

      const { subscriptionId, stripeCustomerId } = await response.json();
      const startDate = new Date();
      const endDate = new Date(startDate);
      endDate.setFullYear(endDate.getFullYear() + 1);

      // Create group if needed
      let groupForId = "";
      if (totalSeats > 1) {
        console.log("creating new group");
        const groupName = "Group " + userData?.lastName;
        const groupDoc = await createGroup(
          userId,
          totalSeats,
          startDate,
          endDate,
          groupName,
        );
        groupForId = groupDoc.id; //TODO: check if groupDoc is null
      }

      const userInfo = {
        access_level: accessLevel,
        membership_start: startDate,
        membership_end: endDate,
        subscription_id: subscriptionId,
        stripe_customer_id: stripeCustomerId,
        group_admin_for: groupForId,
      };

      await updateUserDoc(userId, userInfo);
      dispatch({ type: "UPDATE_ACCESS_LEVEL", payload: accessLevel });

      // Remove search parameters from the URL
      const newUrl = window.location.origin + window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
      if (groupForId !== "") {
        sleep(1000);
        navigate("/admin/group-admin", { replace: true });
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error("Error processing subscription: ", error.message);
      } else {
        console.error("Unknown error processing subscription: ", error);
      }
    } finally {
      console.log("Finished processing subscription.");
      if (isMounted.current) {
        hasStartedOperation.current = false;
      }
    }
  };

  if (user) {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState<{ [key: number]: string }>({});
    const [isGenerating, setIsGenerating] = useState(false);
    const [showSummaryModal, setShowSummaryModal] = useState(false);
    const [summaryData, setSummaryData] = useState<{
      questions: Array<{ question: string; answer: string }>;
      summary: string;
    } | null>(null);

    const [theme, setTheme] = useState<any>(null);
    const [questions, setQuestions] = useState<
      Array<{ id: number; text: string }>
    >([]);

    useEffect(() => {
      const fetchTheme = async () => {
        // Use hardcoded questions for prototype
        const simpleQuestions = [
          {
            id: 1,
            text: "What went well?",
            digDeeper: [],
            whyItMatters: "",
            bestPractices: "",
          },
          {
            id: 2,
            text: "What was your part in that?",
            digDeeper: [],
            whyItMatters: "",
            bestPractices: "",
          },
        ];
        setQuestions(simpleQuestions);
        setTheme({
          systemPrompt:
            "Create an inspiring summary that highlights the user's achievements and their role in making them happen. Structure the response in markdown format with sections for their successes, personal contributions, who they interacted with to get these outcomes. Don't return a title and use ### for the sections. You can exclude sections if there isn't enough information to fill them out. Always end with a thank you for reflection on what went well and let them know to go through the questions as many times as they want, they more the better!",
        });
      };
      fetchTheme();
    }, []);

    const handleAnswerChange = (value: string) => {
      const currentQuestionId = questions[currentQuestion]?.id;
      if (currentQuestionId) {
        setAnswers((prev) => ({
          ...prev,
          [currentQuestionId]: value,
        }));
      }
    };

    const handleNext = () => {
      if (currentQuestion < questions.length - 1) {
        setCurrentQuestion((prev) => prev + 1);
      }
    };

    const handleGenerateSummary = async () => {
      if (!theme || !questions.length) return;

      console.log("System Prompt:", theme.systemPrompt);
      setIsGenerating(true);
      try {
        // Format the content for OpenAI
        const content = questions
          .map((q) => `Question: ${q.text}\nAnswer: ${answers[q.id] || ""}`)
          .join("\n\n");

        console.log("Sending content to OpenAI:", content);

        const response = await generateStructuredInfo(
          content,
          { model: "gpt-4", temperature: 0.7 },
          theme.systemPrompt,
        );

        if (!response?.data) {
          throw new Error("Failed to generate summary");
        }

        const timestamp = new Date().toISOString();
        const completedTheme = {
          userId: user.uid,
          userName: userData
            ? `${userData.firstName} ${userData.lastName}`
            : "Anonymous",
          themeId: "quick-reflection",
          theme_name: "What went well",
          channel: "findingblueberries-landing",
          responses: Object.entries(answers).map(([questionId, content]) => ({
            questionId: parseInt(questionId),
            question:
              questions.find((q) => q.id === parseInt(questionId))?.text || "",
            content,
            createdAt: timestamp,
          })),
          summary: response.data,
          completedAt: timestamp,
        };

        // Save to completedThemes collection and get the document reference
        const docRef = await addDoc(collection(db, "completedThemes"), completedTheme);

        // Set the summary data with the document ID
        setSummaryData({
          ...completedTheme,
          id: docRef.id  // Add the document ID
        });
        
        setShowSummaryModal(true);
      } catch (error: any) {
        console.error("Error generating summary:", error);
        toast.error(error.message || "Failed to generate summary");
      } finally {
        setIsGenerating(false);
      }
    };

    return (
      <>
        {showSuccessBanner && <SuccessBanner />}
        <div className="min-h-screen bg-gradient-to-b from-sky-100 to-indigo-50">
          <div className="flex flex-col items-center justify-center min-h-[75vh] p-4">
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="text-center max-w-4xl w-full"
            >
              {/* Empty space intentionally left for clean layout */}

              <div className="flex flex-col items-center space-y-6 max-w-2xl mx-auto">
                <div className="w-full bg-white rounded-xl shadow-lg p-8">
                  <h2 className="text-2xl font-semibold text-indigo-900 mb-6">
                    Quick Reflection
                  </h2>
                  {questions.length > 0 && (
                    <div className="space-y-4">
                      <QuestionInput
                        question={questions[currentQuestion]?.text || ""}
                        value={answers[questions[currentQuestion]?.id] || ""}
                        onChange={handleAnswerChange}
                        placeholder="Type your answer here..."
                      />
                      <div className="flex justify-end w-full">
                        <LoadingButton
                          isLoading={isGenerating}
                          onClick={
                            currentQuestion < questions.length - 1
                              ? handleNext
                              : handleGenerateSummary
                          }
                          disabled={!answers[questions[currentQuestion]?.id]}
                          showArrow={true}
                        >
                          {currentQuestion < questions.length - 1
                            ? "Next Question"
                            : "Generate Summary"}
                        </LoadingButton>
                      </div>
                    </div>
                  )}
                </div>

                {showSummaryModal && summaryData && (
                  <ThemeSummaryViewer
                    isOpen={showSummaryModal}
                    onClose={() => setShowSummaryModal(false)}
                    completedThemeId={summaryData.id}
                    userId={user?.uid}
                  />
                )}

                <div className="border-t border-indigo-200 pt-12 mt-12 w-full">
                  <div className="space-y-4">
                    <motion.button
                      onClick={() => navigate("/curation/daily")}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="group inline-flex items-center space-x-4 px-8 py-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-xl text-xl font-semibold shadow-lg hover:from-indigo-700 hover:to-purple-700 transition-all duration-200 w-full justify-center"
                    >
                      <CalendarDays className="w-6 h-6 transition-transform group-hover:rotate-6" />
                      <span>Daily</span>
                      <ArrowRight className="w-6 h-6 transition-transform group-hover:translate-x-1" />
                    </motion.button>

                    <motion.button
                      onClick={() => navigate("/curation/milestones")}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="group inline-flex items-center space-x-4 px-8 py-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-xl text-xl font-semibold shadow-lg hover:from-indigo-700 hover:to-purple-700 transition-all duration-200 w-full justify-center"
                    >
                      <Route className="w-6 h-6 transition-transform group-hover:rotate-6" />
                      <span>Milestones</span>
                      <ArrowRight className="w-6 h-6 transition-transform group-hover:translate-x-1" />
                    </motion.button>

                    <motion.button
                      onClick={() => navigate("/curation/foundations")}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="group inline-flex items-center space-x-4 px-8 py-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-xl text-xl font-semibold shadow-lg hover:from-indigo-700 hover:to-purple-700 transition-all duration-200 w-full justify-center"
                    >
                      <BookOpen className="w-6 h-6 transition-transform group-hover:rotate-6" />
                      <span>Foundations</span>
                      <ArrowRight className="w-6 h-6 transition-transform group-hover:translate-x-1" />
                    </motion.button>

                    <motion.button
                      onClick={() => navigate("/dashboard")}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="group inline-flex items-center space-x-4 px-8 py-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-xl text-xl font-semibold shadow-lg hover:from-indigo-700 hover:to-purple-700 transition-all duration-200 w-full justify-center"
                    >
                      <LayoutDashboard className="w-6 h-6 transition-transform group-hover:rotate-6" />
                      <span>Dashboard</span>
                      <ArrowRight className="w-6 h-6 transition-transform group-hover:translate-x-1" />
                    </motion.button>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {showSuccessBanner && <SuccessBanner />}
      {showNewHome ? <TestHome /> : <LandingContentGuestView /> }
    </>
  );
}