import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { toast } from 'react-hot-toast';
import { GroupService, FirebaseGroupRepository, Group, GroupMap} from '../../lib/dal/groups';

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  access_level: string;
  group?: string;
  membership_start?: string;
  membership_end?: string;
}

interface EditUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: User;
  onUserUpdated: () => void;
}

export default function EditUserModal({ isOpen, onClose, user, onUserUpdated }: EditUserModalProps) {
  const [editForm, setEditForm] = useState(user);
  const [loading, setLoading] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);
  const [groupChanged, setGroupChanged] = useState(false);
  const [allGroups, setAllGroups] = useState<GroupMap>({});
  const groupService = new GroupService(new FirebaseGroupRepository());

  useEffect(() => {
    if (user.access_level === 'group_membership') {
      groupService.getAllGroups()
        .then(groups => {
          setAllGroups(groups);
          const group = groups[user.group];
          if (group) {
            setSelectedGroupId(group.id);
          }
        });
    }
  }, []);


  const selectGroup = (groupId: string) => {
    setGroupChanged(true);
    setSelectedGroupId(groupId);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const timestamp = new Date().toISOString();

    try {
      if (groupChanged && selectedGroupId) {
        groupService.addMember(selectedGroupId, user.id);
        if (user.group) {
          groupService.removeMember(user.group, user.id);
        }
      }

      const userRef = doc(db, 'users', user.id);
      await updateDoc(userRef, {
        firstName: editForm.firstName,
        lastName: editForm.lastName,
        group: selectedGroupId,
        access_level: editForm.access_level,
        membership_start: editForm.membership_start,
        membership_end: editForm.membership_end
      });

      toast.success('User updated successfully');
      onUserUpdated();
      onClose();
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error('Failed to update user');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-900">Edit User</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">First Name</label>
              <input
                type="text"
                value={editForm.firstName}
                onChange={(e) => setEditForm({ ...editForm, firstName: e.target.value })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Last Name</label>
              <input
                type="text"
                value={editForm.lastName}
                onChange={(e) => setEditForm({ ...editForm, lastName: e.target.value })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Access Level</label>
            <select
              value={editForm.access_level}
              onChange={(e) => setEditForm({ ...editForm, access_level: e.target.value })}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="free">Free</option>
              <option value="limited_trial">Limited Trial</option>
              <option value="group_membership">Group Membership</option>
              <option value="personal_membership">Personal Membership</option>
              <option value="admin">Admin</option>
            </select>
          </div>

          {editForm.access_level === 'group_membership' && (
            <div className="relative">
                <label className="block text-sm font-medium text-gray-700">Group</label>
                <select
                  value={selectedGroupId}
                  onChange={(e) => selectGroup( e.target.value )}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                >
                {Object.values(allGroups).map(group => (
                  <option key={group.id} value={group.id}>
                    {group.group_name || group.group_code || 'Unknown Group'}
                  </option>
                ))}
                </select>
                </div>
          )}

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Membership Start</label>
              <input
                type="date"
                value={editForm.membership_start?.split('T')[0] || ''}
                onChange={(e) => setEditForm({ ...editForm, membership_start: e.target.value })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Membership End</label>
              <input
                type="date"
                value={editForm.membership_end?.split('T')[0] || ''}
                onChange={(e) => setEditForm({ ...editForm, membership_end: e.target.value })}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
          </div>

          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 hover:text-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50"
            >
              {loading ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}