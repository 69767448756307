import React from 'react';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react';

export default function BuyEnterpriseCard() {
  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      className="bg-white rounded-2xl shadow-xl overflow-hidden cursor-pointer transition-all"
    >
      <div className="p-8">
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">Enterprise Plan</h2>
        <p className="text-gray-600 mb-6">
          Comprehensive support and coaching for enterprise teams
        </p>
        <div className="text-4xl font-bold text-indigo-600 mb-4">
          Contact for pricing
        </div>
        <motion.button
          onClick={(e) => {
            e.stopPropagation();
            window.open(
              'https://calendly.com/brian-findinggood/meeting-with-brian',
              '_blank',
              'noopener,noreferrer'
            );
          }}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className="w-full mb-6 px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
        >
          Schedule Demo
        </motion.button>
        <ul className="space-y-4 mb-8">
          {[
            'All group plan features',
            'Dedicated coaching services (team leads)',
            'Weekly office hours and help sessions',
            'Custom implementation strategy',
            'Priority support',
            'Advanced analytics'
          ].map((feature) => (
            <li key={feature} className="flex items-center">
              <Check className="h-5 w-5 text-green-500 mr-3" />
              <span className="text-gray-600">{feature}</span>
            </li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
}
