import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';

const PUBLIC_ROUTES = ['/payment', '/purchase', '/login', '/signup', '/welcome', '/test-home', '/buy'];

export function RequireAuth({ children }: { children: React.ReactNode }) {
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    // Store the attempted path to redirect back after login
    sessionStorage.setItem('redirectPath', location.pathname);
    // Special handling for Vistage route
    if (location.pathname === '/vistage') {
      return <Navigate to="/vistagesignup" state={{ from: location }} replace />;
    }
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
}