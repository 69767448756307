import React from 'react';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react';
import { PurchaseType, Duration } from '../enums/PurchaseEnums';
import { useAuth } from '../contexts/AuthContext';

interface BuyBulkCardProps {
  duration: Duration;
  purchaseType: PurchaseType;
  setPurchaseType: (type: PurchaseType) => void;
  onCheckout: (type: PurchaseType, email: string) => void;
  quantity: number;
  setQuantity: (quantity: number) => void;
  monthlyPrice: number;
  annualPrice: number;
}

export default function BuyBulkCard({
  duration,
  purchaseType,
  setPurchaseType,
  onCheckout,
  quantity,
  setQuantity,
  monthlyPrice,
  annualPrice
}: BuyBulkCardProps) {
  const { userData } = useAuth();

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      className={`bg-white rounded-2xl shadow-xl overflow-hidden cursor-pointer transition-all ${
        purchaseType === PurchaseType.GROUP ? 'ring-2 ring-indigo-500' : ''
      }`}
      onClick={() => {
        console.log("Group Plan Card Clicked, setting purchaseType to Group");
        setPurchaseType(PurchaseType.GROUP);
      }}
    >
      <div className="p-8">
        <h2 className="text-2xl font-semibold text-gray-900 mb-4">Bulk Purchase</h2>
        <p className="text-gray-600 mb-6">
          Empower your team with actionable insights and shared growth
        </p>
        <div className="space-y-2 mb-6">
          <div className="text-4xl font-bold text-indigo-600">
            ${duration === Duration.MONTHLY ? monthlyPrice : annualPrice}
            <span className="text-lg font-normal text-gray-500">
              /{duration === Duration.MONTHLY ? 'mo' : 'year'} per member
            </span>
          </div>
          <div className="text-2xl font-bold text-purple-600">
            Total: ${(duration === Duration.MONTHLY ? monthlyPrice : annualPrice) * quantity}
            <span className="text-lg font-normal text-gray-500">
              /{duration === Duration.MONTHLY ? 'mo' : 'year'}
            </span>
          </div>
        </div>
        <motion.button
          onClick={(e) => {
            e.stopPropagation();
            console.log("Group Plan Button Clicked, setting purchaseType to Group");
  
            onCheckout(PurchaseType.GROUP, userData?.email || '');
          }}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className="w-full mb-6 px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
        >
          Bulk Purchase
        </motion.button>
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Number of Members (min. 5)
          </label>
          <input
            type="number"
            min="5"
            value={quantity}
            onChange={(e) => setQuantity(Math.max(5, parseInt(e.target.value) || 5))}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <ul className="space-y-4 mb-8">
          {[
            'All individual features',
            'Admin controls',
            'Bulk discount'
          ].map((feature) => (
            <li key={feature} className="flex items-center">
              <Check className="h-5 w-5 text-green-500 mr-3" />
              <span className="text-gray-600">{feature}</span>
            </li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
}
