import React from 'react';
import { Calendar, CreditCard, Clock } from "lucide-react";
import { Link, useLocation } from 'react-router-dom';
import SubscriptionSelector from "./SubscriptionSelector";
import { Subscription } from '../../lib/dal/subscriptions';
import { useAuth } from '../../contexts/AuthContext';

interface SubscriptionDetailViewProps {
  subscriptions: Subscription[];
  selectedSubscription: Subscription | null;
  onSubscriptionSelect: (subscription: Subscription) => void;
}

const formatDate = (date: string | null): string => {
  if (!date) return 'Not set';
  try {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  } catch (e) {
    console.error('Date formatting error:', e);
    return 'Invalid date';
  }
};

const formatPrice = (price: number | null): string => {
  if (typeof price !== 'number') return 'N/A';
  try {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price);
  } catch (e) {
    console.error('Price formatting error:', e);
    return 'Invalid price';
  }
};

export default function SubscriptionDetailView({ 
  subscriptions = [], 
  selectedSubscription, 
  onSubscriptionSelect 
}: SubscriptionDetailViewProps) {
  const location = useLocation();
  const {  user } = useAuth();

  if (!Array.isArray(subscriptions)) {
    console.error('Invalid subscriptions prop');
    return null;
  }

  if (!selectedSubscription) {
    return (
      <div className="w-full max-w-xs mx-auto">
        <h3 className="text-xl font-semibold mb-4 text-gray-900">Select a Subscription</h3>
        <SubscriptionSelector
          subscriptions={subscriptions}
          selectedSubscription={null}
          onSubscriptionSelect={onSubscriptionSelect}
        />
      </div>
    );
  }

  const {
    price_plan,
    start_date,
    end_date,
    renewal_status
  } = selectedSubscription;

  return (
    <div className="w-full bg-gradient-to-r from-indigo-500 to-purple-600 text-white p-6 rounded-lg">
          <h3 className="text-xl font-semibold mb-6 whitespace-nowrap overflow-hidden text-ellipsis">
            Subscription Details: {price_plan.planName}
          </h3>      
      <div className="grid grid-cols-1 md:grid-cols-5 gap-6">
        <div className="md:col-span-3">
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <CreditCard className="w-5 h-5" />
              <span className="font-medium">
                {price_plan?.description}
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <Calendar className="w-5 h-5" />
              <span>
                Start Date: {formatDate(start_date)}
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <Calendar className="w-5 h-5" />
              <span>
                End Date: {formatDate(end_date)}
                {renewal_status === 'active' && ' (Auto-renewing)'}
              </span>
            </div>

            {location.pathname !== '/admin/subscription-admin' && selectedSubscription.admin_id == user.uid && (
              <div className="flex items-center justify-center w-full">
                <Link 
                  to="/admin/subscription-admin"
                  className="text-white hover:text-indigo-100 border border-white/30 rounded px-4 py-2 hover:border-white/60 transition-colors"
                >
                  Manage Subscriptions
                </Link>
              </div>
            )}

          </div>
        </div>
        <div className="md:col-span-2 flex flex-col items-center justify-center space-y-6">
          <div className="text-center">
            <Clock className="w-12 h-12 mx-auto mb-2" />
            <span className="text-lg font-medium">
              Status: {renewal_status || 'Unknown'}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <span className="text-lg font-medium">Code:</span>
            {subscriptions.length === 1 ? (
              <span className="text-lg font-medium">{selectedSubscription.code}</span>
            ) : (
              <SubscriptionSelector
                subscriptions={subscriptions}
                selectedSubscription={selectedSubscription}
                onSubscriptionSelect={onSubscriptionSelect}
                className="bg-transparent text-white border-white"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
