import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { X, FileText, Download, Share2, Trash2 } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { PDFGenerator } from '../shared/PDFGenerator';
import { useAuth } from '../../contexts/AuthContext';
import { getCompletedThemes, CompletedTheme, db } from '../../lib/firebase';
import { collection, addDoc, deleteDoc, doc } from 'firebase/firestore';
import { getThemeById } from '../../lib/firebase/themes';
import { generateStructuredInfo } from '../../lib/openai';
import { createAndCopyShareLink } from '../../lib/utils/share';

import ThemeSummaryViewer from '../shared/ThemeSummaryViewer';

interface DashboardProps {
  onClose?: () => void;
}

export default function Dashboard({ onClose }: DashboardProps) {
  const navigate = useNavigate();
  const { user, userData } = useAuth();
  const [themes, setThemes] = useState<CompletedTheme[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showDebug, setShowDebug] = useState(false);
  const [showOnlyExperienceSummaries, setShowOnlyExperienceSummaries] = useState(false);
  const [selectedThemeId, setSelectedThemeId] = useState<string | null>(null);
  const [showSummaryViewer, setShowSummaryViewer] = useState(false);
  const [themeTitles, setThemeTitles] = useState<Record<string, string>>({});
  const [expandedThemeId, setExpandedThemeId] = useState<string | null>(null);
  const [isGeneratingExperienceSummary, setIsGeneratingExperienceSummary] = useState(false);
  const [experienceSummary, setExperienceSummary] = useState<CompletedTheme | null>(null);
  const [experienceFilter, setExperienceFilter] = useState<string>('all');
  const [hasAllThemesCompleted, setHasAllThemesCompleted] = useState(false);
  const [showPDFModal, setShowPDFModal] = useState(false);
  const [selectedThemeForPDF, setSelectedThemeForPDF] = useState<CompletedTheme | null>(null);
  const [selectedThemes, setSelectedThemes] = useState<string[]>([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isGeneratingMetaSummary, setIsGeneratingMetaSummary] = useState(false);
  const [metaSummaryResponse, setMetaSummaryResponse] = useState<string | null>(null);
  const [showMetaSummaryModal, setShowMetaSummaryModal] = useState(false);
  const [showTitleModal, setShowTitleModal] = useState(false);
  const [metaSummaryTitle, setMetaSummaryTitle] = useState('');
  const [additionalContext, setAdditionalContext] = useState(''); // Added state for additional context


  const handleDownloadPDF = (theme: CompletedTheme) => {
    setSelectedThemeForPDF(theme);
    setShowPDFModal(true);
  };

  const handleViewSummary = (theme: CompletedTheme) => {
    setSelectedThemeId(theme.id);
    setShowSummaryViewer(true);
  };

  const handleShare = async (theme: CompletedTheme) => {
    try {
      await createAndCopyShareLink({
        summary: theme.summary,
        userId: user?.uid
      });
    } catch (error) {
      console.error('Error in handleShare:', error);
    }
  };

  const handleThemeSelect = (themeId: string) => {
    if (selectedThemes.includes(themeId)) {
      setSelectedThemes(selectedThemes.filter(id => id !== themeId));
    } else {
      setSelectedThemes([...selectedThemes, themeId]);
    }
  };

  const handleDeleteSelectedThemes = async () => {
    setShowDeleteConfirm(false);
    try {
      await Promise.all(selectedThemes.map(async themeId => {
        const themeRef = doc(db, 'completedThemes', themeId);
        await deleteDoc(themeRef);
      }));
      setSelectedThemes([]);
      toast.success('Selected themes deleted successfully!');
      const fetchedThemes = await getCompletedThemes(user.uid);
      setThemes(fetchedThemes);
    } catch (error) {
      console.error('Error deleting themes:', error);
      toast.error('Failed to delete selected themes.');
    }
  };


  const handleTitleSubmit = async () => {
    setShowTitleModal(false);
    await handleGenerateMetaSummary();
  };

  const handleGenerateMetaSummary = async () => {
    setIsGeneratingMetaSummary(true);
    setMetaSummaryResponse(null);
    try {
      
      const summaries = await Promise.all(selectedThemes.map(async themeId => {
        const theme = themes.find(t => t.id === themeId);
        return theme?.summary || '';
      }));

      const combinedSummary = summaries.join('\n\n---\n\n');

      const response = await generateStructuredInfo(
        combinedSummary,
        { model: "gpt-4", temperature: 0.7 },
        `Generate a concise meta-summary of these theme summaries, highlighting key patterns and insights. Focus on identifying recurring themes, personal growth patterns, and actionable insights. ${additionalContext ? `Additional Context to Consider: ${additionalContext}` : ''}`
      );

      if (!response?.data) {
        throw new Error("Failed to generate meta summary");
      }

      const selectedThemeData = await Promise.all(selectedThemes.map(async themeId => {
        const theme = themes.find(t => t.id === themeId);
        return {
          responses: theme?.responses || [],
          summary: theme?.summary || ''
        };
      }));

      const combinedResponses = selectedThemeData.flatMap(theme => theme.responses);

      const docRef = await addDoc(collection(db, 'completedThemes'), {
        userId: user.uid,
        userName: userData ? `${userData.firstName} ${userData.lastName}` : 'Anonymous',
        theme_name: metaSummaryTitle || 'Meta Summary',
        channel: 'findingblueberries-dashboard',
        summary: response.data,
        completedAt: new Date().toISOString(),
        themeType: 'meta_summary',
        responses: combinedResponses,
        themeId: 'meta-summary',
        additionalContext: additionalContext || ''
      });

      const updatedThemes = await getCompletedThemes(user.uid);
      setThemes(updatedThemes);

      setMetaSummaryResponse({ id: docRef.id, summary: response.data });
      setShowMetaSummaryModal(true);
      toast.success('Meta summary generated and saved!');
      setSelectedThemes([]);
    } catch (error) {
      console.error('Error generating meta summary:', error);
      toast.error('Failed to generate meta summary.');
      setMetaSummaryResponse('Error generating meta summary. Please try again later.');
    } finally {
      setIsGeneratingMetaSummary(false);
    }
  };

  useEffect(() => {
    const loadThemes = async () => {
      if (!user) return;
      try {
        setLoading(true);
        setError(null);
        const fetchedThemes = await getCompletedThemes(user.uid);

        const themesNeedingTitles = fetchedThemes.filter(theme => !theme.themeTitle);
        if (themesNeedingTitles.length > 0) {
          const titles = await Promise.all(
            themesNeedingTitles.map(async theme => {
              const themeDoc = await getThemeById(theme.themeId);
              return {
                themeId: theme.themeId,
                title: themeDoc?.title || 'Theme'
              };
            })
          );

          const titleMap = titles.reduce((acc, { themeId, title }) => {
            acc[themeId] = title;
            return acc;
          }, {} as Record<string, string>);

          setThemeTitles(titleMap);
        }
        setThemes(fetchedThemes);
      } catch (error) {
        console.error('Error loading themes:', error);
        setError('Failed to load your completed themes. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    loadThemes();
  }, [user]);

  if (loading) {
    return (
      <div className="min-h-screen bg-white">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-indigo-200 border-t-indigo-600 mx-auto"></div>
          <p className="mt-4 text-gray-600">Loading your completed themes...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex items-center justify-between mb-8 w-full">
          <div className="flex items-center space-x-6">
            <h1 className="text-2xl font-bold text-indigo-900">My Completed Themes</h1>
            <button
              onClick={() => setShowOnlyExperienceSummaries(!showOnlyExperienceSummaries)}
              className={`px-4 py-2 rounded-lg text-sm transition-colors ${
                showOnlyExperienceSummaries
                  ? 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              {showOnlyExperienceSummaries ? 'Show All' : 'Show Experience Summaries'}
            </button>
          </div>
          {selectedThemes.length > 0 && (
            <div className="flex space-x-4">
              <button
                onClick={() => setShowDeleteConfirm(true)}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors flex items-center space-x-2"
              >
                <Trash2 className="w-4 h-4" />
                <span>Delete Selected ({selectedThemes.length})</span>
              </button>
              {selectedThemes.length > 1 && (
              <button
                onClick={() => setShowTitleModal(true)}
                className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
                disabled={isGeneratingMetaSummary}
              >
                {isGeneratingMetaSummary ? 'Generating...' : 'Summarize All'}
              </button>
              )}
              {showTitleModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                  <div className="bg-white rounded-lg p-6 max-w-md w-full">
                    <h3 className="text-lg font-medium mb-4">Meta Summary Title</h3>
                    <div className="space-y-4">
                      <input
                        type="text"
                        value={metaSummaryTitle}
                        onChange={(e) => setMetaSummaryTitle(e.target.value)}
                        placeholder="Enter a title (optional)"
                        className="w-full p-2 border border-gray-300 rounded-lg"
                      />
                      <textarea
                        value={additionalContext}
                        onChange={(e) => setAdditionalContext(e.target.value)}
                        placeholder="Add questions to add to the summary generation process"
                        className="w-full p-2 border border-gray-300 rounded-lg h-24 resize-none"
                      />
                    </div>
                    <div className="flex justify-end space-x-4">
                      <button
                        onClick={() => setShowTitleModal(false)}
                        className="px-4 py-2 text-gray-600 hover:text-gray-800"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleTitleSubmit}
                        className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                      >
                        Generate Summary
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {onClose && (
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600"
            >
              ×
            </button>
          )}
        </div>

        {error && (
          <div className="mb-8 bg-red-50 border border-red-200 rounded-lg p-4">
            <p className="text-red-700">{error}</p>
          </div>
        )}
<p className="text-sm text-gray-600 mt-1">Select (click) summaries to delete or create a meta summary</p>

        {themes.length === 0 ? (
          <div className="text-center py-16 bg-white rounded-xl shadow-sm">
            <div className="mb-4">
              <FileText className="w-12 h-12 text-gray-400 mx-auto" />
            </div>
            <div className="space-y-4">
              <p className="text-gray-600">
                No completed themes yet. Start your journey by completing a theme in{' '}
                <button
                  onClick={() => navigate('/experience-test')}
                  className="text-indigo-600 hover:text-indigo-700 font-medium"
                >
                  Experience Test
                </button>!
              </p>

              <button
                onClick={() => setShowDebug(!showDebug)}
                className="text-sm text-indigo-400 hover:text-indigo-500"
              >
                Toggle Debug Info
              </button>

              {showDebug && (
                <div className="text-left p-4 bg-gray-50 rounded-lg">
                  <p className="text-sm text-gray-500">User ID: {user?.uid}</p>
                  <p className="text-sm text-gray-500">Query Path: completedThemes</p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="space-y-6">
            {themes.filter(theme => {
              if (showOnlyExperienceSummaries) {
                return theme.themeType === 'experience_summary';
              }
              if (experienceFilter !== 'all') {
                if (experienceFilter === 'shared-experiences') {
                  return theme.experienceId === 'shared-experiences' || theme.experienceId === 'FkfQnHvmsEdjfME9n3sS';
                }
                return theme.experienceId === experienceFilter;
              }
              return true;
            })
              .map((theme) => (
                <motion.div
                  key={theme.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  className={`bg-white rounded-xl shadow-sm overflow-hidden cursor-pointer transition-all ${
                    theme.themeType === 'experience_summary' ? 'ring-2 ring-indigo-500' : ''
                  } ${
                    selectedThemes.includes(theme.id) ? 'ring-2 ring-blue-500 ring-offset-2' : ''
                  }`}
                  onClick={() => handleThemeSelect(theme.id)}
                >
                  <div className={`px-6 py-4 ${
                    theme.themeType === 'experience_summary'
                      ? 'bg-gradient-to-r from-indigo-100 to-purple-100'
                      : 'bg-gradient-to-r from-indigo-50 to-blue-50'
                  }`}>
                    <div className="flex justify-between items-start">
                      <div>
                        <h3 className="text-xl font-semibold text-indigo-900">
                          {theme.theme_name || theme.themeTitle || themeTitles[theme.themeId] || 'Theme Summary'}
                          {theme.createdFor && (
                            <span className="ml-2 text-base text-indigo-600">
                              (Created for {theme.createdFor})
                            </span>
                          )}
                        </h3>
                        {theme.themeType === 'experience_summary' && (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                            Experience Summary
                          </span>
                        )}
                        <div className="text-sm text-gray-600 mt-2">
                          <p>{theme.completedAt && `Completed on ${new Date(theme.completedAt).toLocaleDateString()}`}</p>
                          <p className="text-xs text-gray-500">Theme ID: {theme.id}</p>
                        </div>
                      </div>
                      <div className="flex space-x-2">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleShare(theme);
                          }}
                          className="p-2 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg transition-colors"
                          title="Share Summary"
                        >
                          <Share2 className="w-5 h-5" />
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleViewSummary(theme);
                          }}
                          className="p-2 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg transition-colors"
                        >
                          <FileText className="w-5 h-5" />
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDownloadPDF(theme);
                          }}
                          className="p-2 text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-lg transition-colors"
                        >
                          <Download className="w-5 h-5" />
                        </button>
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
          </div>
        )}

        {selectedThemeId && user && (
          <ThemeSummaryViewer
            isOpen={showSummaryViewer}
            onClose={() => {
              setShowSummaryViewer(false);
              setSelectedThemeId(null);
            }}
            completedThemeId={selectedThemeId}
            userId={user.uid}
          />
        )}

        {selectedThemeForPDF && (
          <PDFGenerator
            isOpen={showPDFModal}
            onClose={() => {
              setShowPDFModal(false);
              setSelectedThemeForPDF(null);
            }}
            title={selectedThemeForPDF.themeType === 'experience_summary' ? 'Year In Review' : (selectedThemeForPDF.themeTitle || selectedThemeForPDF.summaryTitle || 'Theme')}
            content={selectedThemeForPDF.summary}
            createdFor={selectedThemeForPDF.createdFor}
            responses={selectedThemeForPDF.responses?.map(r => ({
              question: r.question,
              content: r.content
            }))}
          />
        )}

        {showDeleteConfirm && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-6 shadow-lg w-96">
              <p className="text-lg font-medium mb-4">Are you sure you want to delete the selected themes?</p>
              <div className="flex justify-end space-x-4">
                <button
                  onClick={handleDeleteSelectedThemes}
                  className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                >
                  Delete
                </button>
                <button
                  onClick={() => setShowDeleteConfirm(false)}
                  className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {showMetaSummaryModal && metaSummaryResponse && (
          <ThemeSummaryViewer
            isOpen={showMetaSummaryModal}
            onClose={async () => {
              setShowMetaSummaryModal(false);
              setMetaSummaryResponse(null);
              const updatedThemes = await getCompletedThemes(user.uid);
              setThemes(updatedThemes);
            }}
            completedThemeId={metaSummaryResponse.id}
            userId={user.uid}
          />
        )}
      </div>
    </div>
  );
}