import React, { useState } from 'react';
import { X, Search } from 'lucide-react';
import { doc, setDoc, collection, query, where, getDocs, getDoc, updateDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { db } from '../../lib/firebase';
import { toast } from 'react-hot-toast';
import { auth } from '../../lib/firebase';

interface Group {
  id: string;
  group_name: string;
  group_code: string;
  seats_remaining: number;
}

interface AddUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUserAdded: () => void;
}

export default function AddUserModal({ isOpen, onClose, onUserAdded }: AddUserModalProps) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [groupSearch, setGroupSearch] = useState('');
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [groups, setGroups] = useState<Group[]>([]);
  const [showGroupResults, setShowGroupResults] = useState(false);
  const [accessLevel, setAccessLevel] = useState<string>('free');
  const [loading, setLoading] = useState(false);

  //TODO: Implement searchGroups function in the groups Data Access Layer (lib/dal/groups.ts)
  const searchGroups = async (term: string) => {
    try {
      const groupsRef = collection(db, 'groups');
      const q = query(groupsRef);
      const snapshot = await getDocs(q);
      
      const results = snapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        } as Group))
        .filter(group => 
          group.group_name.toLowerCase().includes(term.toLowerCase()) &&
          group.seats_remaining > 0
        );
      
      setGroups(results);
    } catch (error) {
      console.error('Error searching groups:', error);
      toast.error('Failed to search groups');
    }
  };

  const handleGroupSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setGroupSearch(term);
    setShowGroupResults(true);
    searchGroups(term);
  };

  const selectGroup = (group: Group) => {
    setSelectedGroup(group);
    setGroupSearch(group.group_name);
    setShowGroupResults(false);
    setAccessLevel('group_membership');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const timestamp = new Date().toISOString();

    try {
      // Check if email already exists in Firestore
      const usersRef = collection(db, 'users');
      const q = query(
        usersRef, 
        where('email', '==', email.toLowerCase()),
        where('access_level', '==', 'free')
      );
      const snapshot = await getDocs(q);
      if (!snapshot.empty) {
        throw new Error('A user with this email already exists');
      }

      if (accessLevel === 'group_membership' && !selectedGroup) {
        throw new Error('Please select a group for group membership');
      }

      // Create user in Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // Create user document data
      const userData = {
        firstName,
        lastName,
        email: email.toLowerCase(),
        group: selectedGroup?.id || null,
        access_level: accessLevel,
        createdAt: timestamp,
        membership_start: accessLevel === 'group_membership' ? timestamp : null,
        membership_end: selectedGroup ? selectedGroup.end_date : null
      };

      // Create user document in Firestore
      await setDoc(doc(db, 'users', userCredential.user.uid), userData);

      // If group membership, update group
      if (selectedGroup) {
        const groupRef = doc(db, 'groups', selectedGroup.id);
        const groupDoc = await getDoc(groupRef);
        const groupData = groupDoc.data();
        await updateDoc(groupRef, {
          members: groupData.members ? [...groupData.members, userCredential.user.uid] : [userCredential.user.uid],
          seats_used: (groupData.seats_used || 0) + 1,
          seats_remaining: Math.max(0, (groupData.seats_remaining || 0) - 1)
        });
      }

      toast.success(`User ${firstName} ${lastName} added successfully`);
      onUserAdded();
      onClose();
      
      // Reset form
      setFirstName('');
      setLastName('');
      setEmail('');
      setPassword('');
      setSelectedGroup(null);
      setAccessLevel('free');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use' || error.message === 'A user with this email already exists') {
        toast.error('A user with this email already exists');
      } else if (error.code === 'auth/invalid-email') {
        toast.error('Invalid email address');
      } else {
        console.error('Error adding user:', error);
        toast.error('Failed to add user. Please try again.');
        // Clean up by signing out the temporary auth instance
        await signOut(auth);
      }
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-900">Add New User</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">First Name</label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Last Name</label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              minLength={6}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Access Level</label>
            <select
              value={accessLevel}
              onChange={(e) => setAccessLevel(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="free">Free</option>
              <option value="limited_trial">Limited Trial</option>
              <option value="group_membership">Group Membership</option>
              <option value="personal_membership">Personal Membership</option>
              <option value="admin">Admin</option>
            </select>
          </div>

          {accessLevel === 'group_membership' && (
            <div className="relative">
              <label className="block text-sm font-medium text-gray-700">Select Group</label>
              <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                <input
                  type="text"
                  value={groupSearch}
                  onChange={handleGroupSearch}
                  onFocus={() => setShowGroupResults(true)}
                  placeholder="Search for a group..."
                  className="mt-1 block w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              
              {showGroupResults && groups.length > 0 && (
                <div className="absolute z-10 mt-1 w-full bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto">
                  {groups.map(group => (
                    <div
                      key={group.id}
                      onClick={() => selectGroup(group)}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    >
                      <div className="font-medium">{group.group_name}</div>
                      <div className="text-sm text-gray-500">
                        {group.seats_remaining} seats available
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-700 hover:text-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading || (accessLevel === 'group_membership' && !selectedGroup)}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 disabled:opacity-50"
            >
              {loading ? 'Adding...' : 'Add User'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}