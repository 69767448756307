import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useParams } from 'react-router-dom';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { ArrowRight } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import type { Experience, Theme, Curation } from '../lib/firebase/types';
import toast from 'react-hot-toast';

interface ExperienceWithThemes extends Experience {
  themes?: Theme[];
  completedThemes?: number;
  totalThemes?: number;
}

export default function CurationView() {
  const navigate = useNavigate();
  const { curationRoute } = useParams();
  const { user } = useAuth();
  const [experiences, setExperiences] = useState<ExperienceWithThemes[]>([]);
  const [curation, setCuration] = useState<Curation | null>(null);
  const [loading, setLoading] = useState(true);

  const loadCuration = async () => {
    try {
      const curationsRef = collection(db, 'curations');
      const curationsQuery = query(
        curationsRef,
        where('route', '==', curationRoute)
      );
      const snapshot = await getDocs(curationsQuery);
      if (snapshot.empty) {
        console.error('No curation found for route:', curationRoute);
        return;
      }
      const curation = snapshot.docs[0].data();
      setCuration(curation as Curation);
      
      const experiencesQuery = query(
        collection(db, 'experiences'),
        where('__name__', 'in', curation.experienceIds),
        where('isActive', '==', true),
        orderBy('order', 'asc')
      );
      const experiencesSnapshot = await getDocs(experiencesQuery);
      const experiencesData = experiencesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as Experience));

      console.log('Loaded experiences:', experiencesData);

      // Load themes for each experience
      const experiencesWithThemes = await Promise.all(
        experiencesData.map(async (experience) => {
          console.log("experience ", experience.id);
          const themesQuery = query(
            collection(db, 'themes'),
            where('experienceId', '==', experience.id),
            orderBy('order', 'asc')
          );
          const themesSnapshot = await getDocs(themesQuery);
          const themes = themesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          })) as Theme[];

          console.log("themes", themes);

          let uniqueCompletedThemes = new Set();
          if (user) {
            const completedQuery = query(
              collection(db, 'completedThemes'),
              where('userId', '==', user.uid),
              where('experienceId', '==', experience.id),
              where('themeType', '==', 'theme')
            );
            const completedSnapshot = await getDocs(completedQuery);
            completedSnapshot.docs.forEach(doc => {
              uniqueCompletedThemes.add(doc.data().themeId);
            });
          }

          return {
            ...experience,
            themes,
            completedThemes: Math.min(uniqueCompletedThemes.size, themes.length),
            totalThemes: themes.length
          };
        })
      );

      console.log('Loaded experiences:', experiencesWithThemes);
      setExperiences(experiencesWithThemes);
    } catch (error) {
      console.error('Error loading curation:', error);
      toast.error('Failed to load curation');
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    loadCuration();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
      </div>
    );
  }


  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-indigo-900 mb-4">
            {curation ? curation.title : 'Deeper Reflections'}
          </h1>
          <p className="text-xl text-indigo-700">
            {curation ? curation.explainer : 'Curated experiences to help you reflect and grow'}
          </p>
        </div>

        <div className="space-y-8">
          {experiences.map((experience) => (
            <motion.div
              key={experience.id}
              whileHover={{ scale: 1.02 }}
              className={`bg-white rounded-2xl shadow-xl overflow-hidden cursor-pointer ${
                experience.completedThemes === experience.totalThemes ? 'border-2 border-indigo-200' : ''
              }`}
              onClick={() => navigate(`/${experience.route}`)}
            >
              <div className="p-8">
                <div className="flex items-start justify-between">
                  <div>
                    <h2 className="text-2xl font-semibold text-indigo-900 mb-4">
                      {experience.title}
                    </h2>
                    <p className="text-indigo-600 mb-6">
                      {experience.description}
                    </p>
                    <div className="flex flex-wrap gap-3">
                      <span className="px-3 py-1 bg-indigo-50 text-indigo-700 rounded-full text-sm">
                        {experience.themes?.length || 0} Theme{experience.themes?.length !== 1 ? 's' : ''}
                      </span>
                      <span className="px-3 py-1 bg-indigo-50 text-indigo-700 rounded-full text-sm">
                        {(experience.themes?.reduce((total, theme) => total + theme.questions.length, 0) || 0)} Questions
                      </span>
                    </div>
                  </div>
                  <ArrowRight className="w-5 h-5 text-indigo-600 mt-2" />
                </div>
              </div>
              <div className="px-8 py-4 bg-gradient-to-r from-indigo-50 to-blue-50 flex justify-between items-center">
                <span className="text-sm text-indigo-700">
                  {!experience.completedThemes
                    ? 'Start your journey' 
                    : experience.completedThemes === experience.totalThemes 
                      ? 'Experience completed!' 
                      : `${experience.completedThemes}/${experience.totalThemes} themes completed`}
                </span>
                <button className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors">
                  {!experience.completedThemes
                    ? 'Begin Experience' 
                    : 'View Experience'}
                </button>
              </div>
            </motion.div>
          ))}
        </div>

        {experiences.length === 0 && (
          <div className="text-center text-gray-500">
            More curated experiences coming soon...
          </div>
        )}
      </div>
    </div>
  );
}
