import React, { createContext, useContext, useState, useEffect, useReducer } from 'react';
import { User as FirebaseUser, onAuthStateChanged, signOut as firebaseSignOut } from 'firebase/auth';
import { auth, db } from '../lib/firebase';
import { doc, getDoc } from 'firebase/firestore';

interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  access_level?: string;
  createdAt: string;
  group_admin_for?: string[];
}

interface AuthContextType {
  user: FirebaseUser | null;
  userData: UserData | null;
  signInWithGoogle: () => Promise<void>;
  signOut: () => Promise<void>;
  dispatch: React.Dispatch<AuthAction>;
}

type AuthAction = 
  | { type: 'SET_USER_DATA'; payload: UserData }
  | { type: 'UPDATE_ACCESS_LEVEL'; payload: string };

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const authReducer = (state: UserData | null, action: AuthAction): UserData | null => {
  console.log("authReducer", state, action);
  switch (action.type) {
    case 'SET_USER_DATA':
      return action.payload;
    case 'UPDATE_ACCESS_LEVEL':
      return state ? { ...state, access_level: action.payload } : state;
    default:
      return state;
  }
};

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<FirebaseUser | null>(null);
  const [userData, dispatch] = useReducer(authReducer, null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            dispatch({ type: 'SET_USER_DATA', payload: userDoc.data() as UserData });
          }
        } catch (error) {
          console.error('Error loading user data:', error);
          dispatch({ type: 'SET_USER_DATA', payload: null });
        }
      } else {
        dispatch({ type: 'SET_USER_DATA', payload: null });
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const signInWithGoogle = async () => {
    // TODO: Implement Google sign-in if needed
  };

  const signOut = async () => {
    await firebaseSignOut(auth);
    window.location.href = '/login';
  };

  if (loading) {
    return <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
    </div>;
  }

  return (
    <AuthContext.Provider value={{ user, userData, signInWithGoogle, signOut, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}