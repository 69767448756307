import { FbbUser } from "../../lib/dal/users";
import { 
    collection, 
    query,
    Firestore, 
    CollectionReference, 
    DocumentData, 
    updateDoc,
    doc, 
    getDoc, 
    orderBy,
    getDocs,
    where,
    addDoc
} from 'firebase/firestore';
import { db } from '../firebase';

export interface Group {
    id?: string;
    group_name?: string;
    group_code?: string;
    group_admin?: string;
    total_seats?: number;
    seats_used?: number;
    seats_remaining?: number;
    members?: string[];
    created_date?: string;
    start_date?: string;
    end_date?: string;
  }

 export interface GroupMap {
    [key: string]: Group;
  }



const generateGroupCode = async (): Promise<string> => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    let isUnique = false;

    while (!isUnique) {
        result = '';
        for (let i = 0; i < 5; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
        }
        isUnique = await verifyGroupCodeUnique(result);
    }

    return result;
};

const verifyGroupCodeUnique = async (groupCode: string): Promise<boolean> => {
    const groupsRef = collection(db, 'groups');
    const q = query(groupsRef, where('group_code', '==', groupCode));
    const snapshot = await getDocs(q);
    return snapshot.empty;
};

// create interface for group functions
export interface IGroupRepository {
    getGroup: (groupId: string) => Promise<Group>;
    getGroups: () => Promise<Group[]>;
    getAllGroups: () => Promise<GroupMap>;
    createGroup: (group: Group) => Promise<Group>;
    updateGroup: (group: Group) => Promise<Group>;
    deleteGroup: (groupId: string) => Promise<void>;
    addMember: (groupId: string, memberId: string) => Promise<Group>;
    removeMember: (groupId: string, memberId: string) => Promise<Group>;
    removeMemberfromGroups: (memberId: string) => Promise<void>;
}

export class MockGroupRepository implements IGroupRepository {

    async getGroup(groupId: string): Promise<Group> {
        console.log('getGroup', groupId);
        return Promise.resolve({} as Group);
    }

    async getGroups(): Promise<Group[]> {
        console.log('getGroups');
        return Promise.resolve([]);
    }

    async createGroup(group: Group): Promise<Group> {
       console.log('createGroup', group);
       return Promise.resolve(group);
    }

    async updateGroup(group: Group): Promise<Group> {
        console.log('updateGroup', group);
        return Promise.resolve(group);
    }

    async deleteGroup(groupId: string): Promise<void> {
        console.log('deleteGroup', groupId);
        return Promise.resolve();
    }

    async addMember(groupId: string, memberId: string): Promise<Group> {
        console.log('addMember', groupId, memberId);
        return Promise.resolve({} as Group);
    }

    async removeMember(groupId: string, memberId: string): Promise<Group> {
        console.log('removeMember', groupId, memberId);
        return Promise.resolve({} as Group);
    }

    async removeMemberfromGroups(memberId: string): Promise<void> {
        console.log('removeMemberfromGroups', memberId);
        return Promise.resolve();
    }

    async getAllGroups(): Promise<GroupMap> {
        console.log('getAllGroups');
        return Promise.resolve({});
    }
}

const GROUPTABLE = 'groups';
//TODO move to a firebase specific file 
export class FirebaseGroupRepository implements IGroupRepository {
    private db: Firestore;
    private groupsCollectionRef: CollectionReference<DocumentData>;

    constructor() {
        this.db = db;
        this.groupsCollectionRef = collection(this.db, GROUPTABLE);
    }

    async getGroup(groupId: string): Promise<Group> {
        const groupDoc = await getDoc(doc( this.db, GROUPTABLE, groupId)); 
        if (!groupDoc.exists) {
            throw new Error('Group not found');
        }
        const group = groupDoc.data() as Group;
        return { ...group, id: groupDoc.id };
    }

    async getGroups(): Promise<Group[]> {
        throw new Error('Method not implemented.');
        const groupsQuery = await this.groupsCollection.get();
        const groups: Group[] = [];
        groupsQuery.forEach((groupDoc) => {
            const group = groupDoc.data() as Group;
            groups.push({ ...group, id: groupDoc.id });
        });
        return groups;
    }

    async createGroup(group: Group): Promise<Group> {

      console.log('Creating group ' + group.group_name);
      const groupCode = await generateGroupCode();
      //TODO: add properties to group
      group.group_code = groupCode;
      group.created_date = new Date().toISOString();
      if (!group.total_seats) {
        group.seats_used = 1;
        group.seats_remaining = 0; //TODO do the math
      }
      const groupRef = await addDoc(this.groupsCollectionRef, group);
      return { ...group, id: groupRef.id };
    }

    async updateGroup(group: Group): Promise<Group> {
        const groupRef = doc( this.db, GROUPTABLE, group.id)
        await updateDoc(groupRef, group, { merge: true });
        return group;
    }

    async deleteGroup(groupId: string): Promise<void> {
        await this.groupsCollection.doc(groupId).delete();
    }

    async addMember(groupId: string, memberId: string): Promise<Group> {
        const group = await this.getGroup(groupId);
        if (!group.members) {
            group.members = [];
        }
        if (group.seats_remaining <= 0) { 
            throw new Error('No seats remaining in this group');
        }
        group.members.push(memberId);
        group.seats_remaining =  Math.max(0, (group.seats_remaining || 0) - 1);
        group.seats_used = group.members.length;

        return this.updateGroup(group);
    }

    async removeMember(groupId: string, memberId: string): Promise<Group> {
        const group = await this.getGroup(groupId);
        if (group.members) {
            //TODO: update counts
            group.members = group.members.filter((id) => id !== memberId);
            group.seats_remaining =  Math.max(0, (group.seats_remaining || 0) + 1);
            group.seats_used = group.members.length;

            return this.updateGroup(group)
        }
        return group;
    }

    // user the user.group to discover which groups the user is in
    async removeMemberfromGroup(user: FbbUser): Promise<void> {
        const groupId = user.group;
        this.removeMember(groupId, user.id);
        // update UserDoc  - user.removeGroup(groupId)
    }

    // user the user.group to discover which groups the user is in
    async removeMemberfromGroups(memberId: string): Promise<void> {
        // get UserDoc as FbbUser
        //TODO: just use Frebase  getDoc until we have a user repository
        const user = await this.userRepository.getUser(memberId);
        this.removeMember(user.group, user.id);
    }

    async getAllGroups(): Promise<GroupMap> {
        const q = query(this.groupsCollectionRef, orderBy('group_name'));
        const querySnapshot = await getDocs(q);

        return querySnapshot.docs.reduce((acc, doc) => ({
            ...acc,
            [doc.id]: {
              id: doc.id,
              ...doc.data()
            } as Group
          }), {} as GroupMap);
    }
}


export class GroupService {
    private groupRepository: IGroupRepository;

    constructor(groupRepository: IGroupRepository) {
        this.groupRepository = groupRepository;
    }

    async getGroup(groupId: string): Promise<Group> {
        return this.groupRepository.getGroup(groupId);
    }

    async getGroups(): Promise<Group[]> {
        return this.groupRepository.getGroups();
    }

    async createGroup(group: Group): Promise<Group> {
        return this.groupRepository.createGroup(group);
    }

    async updateGroup(group: Group): Promise<Group> {
        return this.groupRepository.updateGroup(group);
    }

    async deleteGroup(groupId: string): Promise<void> {
        return this.groupRepository.deleteGroup(groupId);
    }

    async addMember(groupId: string, memberId: string): Promise<Group> {
        return this.groupRepository.addMember(groupId, memberId);
    }

    async removeMember(groupId: string, memberId: string): Promise<Group> {
        return this.groupRepository.removeMember(groupId, memberId);
    }

    async removeMemberfromGroups(memberId: string): Promise<void> {
        return this.groupRepository.removeMemberfromGroups(memberId);
    }

    async getAllGroups(): Promise<GroupMap> {
        return this.groupRepository.getAllGroups();
    }
}
