import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';
import { toast } from 'react-hot-toast';
import React from 'react';

interface ShareData {
  summary: string;
  userId: string;
  [key: string]: any; // Allow for additional fields
}

const copyToClipboard = async (text: string): Promise<boolean> => {
  try {
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(text);
      return true;
    }
    
    // Fallback for iOS
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      textArea.remove();
      return true;
    } catch (error) {
      textArea.remove();
      return false;
    }
  } catch (error) {
    console.error('Failed to copy text:', error);
    return false;
  }
};

export const createAndCopyShareLink = async (shareData: ShareData): Promise<void> => {
  try {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 7);

    const data = {
      ...shareData,
      createdAt: new Date().toISOString(),
      expiresAt: expirationDate.toISOString(),
    };

    const docRef = await addDoc(collection(db, 'publicShares'), data);
    const shareUrl = `${window.location.origin}/share/${docRef.id}`;
    
    const success = await copyToClipboard(shareUrl);
    
    if (success) {
      toast.success('Share link copied to clipboard! Link expires in 7 days.');
    } else {
      toast((t) => {
        return React.createElement('div', {
          onClick: () => toast.dismiss(t.id),
          children: [
            React.createElement('div', {
              key: 'input-container',
              className: 'relative',
              children: [
                React.createElement('input', {
                  key: 'input',
                  type: 'text',
                  readOnly: true,
                  value: shareUrl,
                  className: 'w-full p-3 pr-24 border rounded-lg bg-gray-50 text-gray-900 font-mono text-sm',
                  onClick: (e: React.MouseEvent<HTMLInputElement>) => {
                    e.currentTarget.select();
                    copyToClipboard(shareUrl).then(success => {
                      if (success) {
                        toast.success('Link copied!', { 
                          id: t.id,
                          duration: 2000  // 2 seconds duration
                        });
                      }
                    });
                  }
                }),
                React.createElement('button', {
                  key: 'copy-button',
                  className: 'absolute right-2 top-2 px-3 py-1 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 text-sm font-medium transition-colors',
                  onClick: (e: React.MouseEvent) => {
                    e.stopPropagation();
                    copyToClipboard(shareUrl).then(success => {
                      if (success) {
                        toast.success('Link copied!', { 
                          id: t.id,
                          duration: 2000  // 2 seconds duration
                        });
                      }
                    });
                  },
                  children: 'Copy'
                })
              ]
            }),
            React.createElement('p', {
              key: 'expiry',
              className: 'mt-3 text-sm text-gray-500',
              children: 'This link will expire in 7 days'
            })
          ]
        });
      }, {
        duration: 2000,  // Keep the manual copy UI visible for longer
        style: {
          background: 'none',
          boxShadow: 'none',
          padding: '0',
          maxWidth: '500px',
          width: '100%'
        }
      });
    }
  } catch (error) {
    console.error('Error creating share:', error);
    toast.error('Failed to create share link');
    throw error;
  }
}; 