
import React from 'react';

interface QuestionInputProps {
  question: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

export const QuestionInput: React.FC<QuestionInputProps> = ({
  question,
  value,
  onChange,
  placeholder = 'Enter as little or as much as you want. Use @name_here to reference people (coworkers, friends, family) and #context_here to mention reoccuring topics, projects, goals, meetings etc.'
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className="space-y-2">
      <label className="block text-lg text-purple-800 font-medium">{question}</label>
      <textarea
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        className="w-full min-h-[120px] p-3 border border-purple-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
      />
    </div>
  );
};
