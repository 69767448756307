import React, { useState, useEffect, useRef } from 'react';
import { ChevronDown, Settings } from 'lucide-react';
import type { Curation } from '../../lib/firebase/types';

interface CurationSelectorProps {
  curations: Curation[];
  selectedCuration: Curation | null;
  onCurationSelect: (curation: Curation) => void;
  onEdit: () => void;
}

export default function CurationSelector({ curations, selectedCuration, onCurationSelect, onEdit }: CurationSelectorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="flex items-center justify-between w-full px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50">
        <span className="text-sm font-medium text-gray-900">
          {selectedCuration?.title || 'Select a curation'}
        </span>
        <div className="flex items-center space-x-2">
          <button onClick={onEdit} title="Edit Curation Properties">
            <Settings className="w-4 h-4 text-gray-400" />
          </button>
          <button onClick={() => setIsOpen(!isOpen)}>
            <ChevronDown className="w-5 h-5 text-gray-400" />
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto">
          {curations.map(curation => (
            <button
              key={curation.id}
              onClick={() => {
                onCurationSelect(curation);
                setIsOpen(false);
              }}
              className={`w-full px-4 py-2 text-left hover:bg-gray-100 ${
                selectedCuration?.id === curation.id ? 'bg-indigo-50' : ''
              }`}
            >
              {curation.title}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
